import React, { useState, useEffect, useCallback } from 'react';

import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer,
    GoogleMapLoader,
    InfoWindow
} from "react-google-maps";


const MapWithAMarker = withScriptjs(withGoogleMap(props =>

    <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: 33.7590, lng: -84.3880 }}
    >
        {props.markers}
        <DirectionsRenderer
            directions={props.direction}
        />
    </GoogleMap>
));



export default MapWithAMarker


// import React, { useState, useEffect, useCallback } from 'react';

// const { compose, withProps, lifecycle } = require("recompose");
// const {
//     withScriptjs,
//     withGoogleMap,
//     GoogleMap,
//     DirectionsRenderer,
// } = require("react-google-maps");

// const MapWithADirectionsRenderer = compose(
//     withProps({
//         googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBb3F_BDIggsttZOaS87O5L0bcRN3_R1Qg&v=3.exp&libraries=geometry,drawing,places",
//         loadingElement: <div style={{ height: `100%` }} />,
//         containerElement: <div style={{ height: `400px` }} />,
//         mapElement: <div style={{ height: `100%` }} />,
//     }),
//     withScriptjs,
//     withGoogleMap,
//     lifecycle({
//         componentDidMount() {
//             const google = new window.google

//             const DirectionsService = google.maps.DirectionsService();

//             DirectionsService.route({
//                 origin: google.maps.LatLng(41.8507300, -87.6512600),
//                 destination: google.maps.LatLng(41.8525800, -87.6514100),
//                 travelMode: google.maps.TravelMode.DRIVING,
//             }, (result, status) => {
//                 if (status === google.maps.DirectionsStatus.OK) {
//                     this.setState({
//                         directions: result,
//                     });
//                 } else {
//                     console.error(`error fetching directions ${result}`);
//                 }
//             });
//         }
//     })
// )(props =>

//     <GoogleMap
//         defaultZoom={7}
//         defaultCenter={new window.google.maps.LatLng(41.8507300, -87.6512600)}
//     >
//         {props.directions && <DirectionsRenderer directions={props.directions} />}
//     </GoogleMap>
// );
// export default MapWithADirectionsRenderer

// {/* <MapWithADirectionsRenderer /> */ }