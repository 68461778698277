
import {
    SET_LOGO_BANNER

} from '../../constant/service/ActionTypes'
const initialState = {
    images: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGO_BANNER: {
            return { ...state, images: action.payload }
        };
    }
    return state;

}