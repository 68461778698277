import React from 'react';

const CostRenderer = (props) => {
    if (props.cost > 0 && props.cost < 99) {
        console.log('props.cost', props.cost)
    }

    const price = props.cost >= 0 && props.cost <= 99 ? '$' : props.cost >= 99 && props.cost <= 999 ? '$$' : props.cost >= 999 && props.cost <= 99999 ? '$$$' : 'no'

    return (

        <tr>
            <td>{props.cost.type}</td>
            <td>{(props.cost.cost >= 0) && (props.cost.cost <= 99) ? '$' : (props.cost.cost >= 99) && (props.cost.cost <= 999) ? '$$' : (props.cost.cost >= 999) && (props.cost.cost < 99999) ? '$$$' : 'N/A'}</td>
            <td>{props.cost.unit}</td>
        </tr>
    )
}

export default CostRenderer;