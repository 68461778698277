import React, { useState, useEffect, useCallback } from 'react';
import { Route, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import ServiceComponents from '../../components/search/Service'
import * as searchActions from './Action';
import CostRenderer from './CostRender'

function parseUrl(url) {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
        return `http://${url}`;
    }
    return url;
}

const SearchWizardResultPanelCondensed = (props) => {
    const dispatch = useDispatch();
    const search = useSelector(state => state.search);


    const getOrganizationName = () => {
        // console.log('this.props.service.org_name',this.props.service.org_name)
        let title = props.service.org_name;
        let subTitle = props.service.service_code ? `(${props.service.service_code.description})` : '';


        return (
            <h2>{title} <span>{subTitle}</span></h2>
            // <div className="row">

            //     {/* <a>Contact</a> */}
            // </div>
            // <span>
            //     <strong>{title}</strong> {subTitle}<br />
            // </span>
        );
    }

    const getServiceAddress = () => {
        var addresses = props.service.addresses.map(function (address, index) {
            if (address.address_type == 0) {
                return (
                    <li key={address.id.toString()}>
                        <i><img src="assets/icon/indicator.png" /></i>
                        <span>
                            {address.address_line_1 + " " + (address.address_line_2 ? address.address_line_2 + " " : "") + " " + address.city + ", " + address.zip}
                        </span>
                    </li>

                );
            }
        });

        return (
            <span>
                {addresses}
            </span>
        );
    }

    const getServicePhoneNumbers = () => {
        if (props.service.phones && props.service.phones.length > 0) {
            var phones = _.sortBy(props.service.phones, 'phone_type').map(function (phone) {
                if (phone.phone_type == 1) {
                    return (
                        <li key={phone.id}>
                            <i><img src="assets/icon/phone.png" /></i>
                            <span>{"(" + phone.area_code + ") " + phone.phone_number} {phone.tty ? (<span><i className="fa fa-fw fa-tty text-success" data-tip="TTY Enabled"></i></span>) : ""}</span>
                        </li>
                        // <span key={phone.id}>
                        //     <i className="fa fa-fw fa-1x fa-phone"></i> {"(" + phone.area_code + ") " + phone.phone_number} {phone.tty ? (<span><i className="fa fa-fw fa-tty text-success" data-tip="TTY Enabled"></i></span>) : ""}
                        //     <br />
                        // </span>
                    );
                } else if (phone.phone_type == 3) {
                    return (
                        <li key={phone.id}>
                            <i><img src="assets/icon/address.png" /></i>
                            <span>{"(" + phone.area_code + ") " + phone.phone_number} {phone.tty ? (<span><i className="fa fa-fw fa-tty text-success" data-tip="TTY Enabled"></i></span>) : ""}</span>
                        </li>
                        // <span key={phone.id}>
                        //     <i className="fa fa-fw fa-1x fa-fax"></i> {"(" + phone.area_code + ") " + phone.phone_number} {phone.tty ? (<span><i className="fa fa-fw fa-tty text-success" data-tip="TTY Enabled"></i></span>) : ""}
                        //     <br />
                        // </span>
                    );
                }
            });

            return (
                <span>
                    {phones}
                </span>
            );
        }
        return <span />;
    }

    const getServiceEmail = () => {
        if (props.service.emails && props.service.emails.length > 0) {
            var emails = props.service.emails.map((email) => {
                return (
                    <li key={email.id}>
                        <i><img src="assets/icon/envelope_blue.png" /></i>
                        <span><a href="#">{email.email}</a></span>
                    </li>

                );
            });
            return (
                <span>
                    {emails}
                </span>
            );
        }
    }

    const getServiceUrl = () => {
        if (props.service.url && props.service.url.length > 0) {
            return (
                <li>
                    <i><img src="assets/icon/globe.png" /></i>
                    <span><a href={parseUrl(props.service.url)} target='_blank'>{parseUrl(props.service.url)}</a></span>
                </li>

            );
        }
    }

    const getCostTable = () => {
        // console.log('props.service.details', props.service.details)
        if (props.service.details) {
            const costs = props.service.details.costs;
            const units = props.service.details.units;
            const types = props.service.details.types;
            const serviceCosts = [];
            const serviceCostsEmpety = []

            const serviceCostsTwo = []


            const empetyCostObj =
                <tr>
                    <td></td>
                    <td>N/A</td>
                    <td></td>
                </tr >;



            if (costs) {
                for (let i = 0; i < costs.values.length; i++) {
                    const cost = { cost: costs.values[i], type: types.values[i], unit: units.values[i] };
                    console.log('costs', cost.cost)
                    // if (cost.cost > 0 && cost.cost < 99) {
                    //     serviceCostsTwo.push('$')
                    // } else if (cost.cost > 99 && cost.cost < 999) {
                    //     serviceCostsTwo.push('$$')
                    // } else if (cost.cost > 999 && cost.cost < 9999) {
                    //     serviceCostsTwo.push('$$$')
                    // } else {
                    //     serviceCostsTwo.push('$')
                    // }

                    serviceCosts.push(<CostRenderer key={i} cost={cost} price={costs.values[i]} />);
                };
            } else {
                serviceCostsEmpety.push(empetyCostObj)

            }
            console.log(serviceCosts)
            return (
                <div className="ibox-content">
                    <label>Costs</label>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Cost Type</th>
                                <th>Amount</th>
                                <th>Cost Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviceCosts}
                            {serviceCostsEmpety}
                        </tbody>
                    </table>
                </div>
            )

            // if (serviceCosts && serviceCosts.length > 0) {
            //     var costDoller = serviceCosts.map((costd) => {
            //         return (
            //             <li>
            //                 <i><img src="assets/icon/pricing.png" /></i>
            //                 <span>{costd}</span>
            //             </li>

            //         );
            //     });
            //     return (
            //         <span>
            //             {costDoller}
            //         </span>
            //     );
            // }


        }
        return <div />;
    }





    return (
        // <section class="main-form-sec">
        //     <div class="container">
        //      <div class="row">

        <div class="col-lg-6">
            <div class="form-blue-box">
                <div class="form-blue-head border-bottom">
                    {getOrganizationName()}
                    {/* <a onClick={() => props.handleFeedback()} data-toggle="modal" data-target="#myModal">Change request</a> */}
                    {/* <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Open Modal</button> */}
                </div>
                <div class="form-blue-body">
                    <ul>

                        {getServiceAddress()}
                        {getServicePhoneNumbers()}
                        {getServiceEmail()}
                        {getServiceUrl()}
                        <div className="row">
                            {getCostTable()}
                        </div>

                        {/* <li>
                                        <i><img src="assets/icon/phone.png" /></i>
                                        <span>(866) 552 - 4464 </span>
                                    </li> */}
                        {/* <li>
                                        <i><img src="assets/icon/address.png" /></i>
                                        <span>(770) 408 - 3001</span>
                                    </li> */}
                        {/* <li>
                                        <i><img src="assets/icon/envelope_blue.png" /></i>
                                        <span><a href="#">abc@gmail.com</a></span>
                                    </li> */}
                        {/* <li>
                                        <i><img src="assets/icon/globe.png" /></i>
                                        <span><a href="#">http://aging.dhs.georgia.gov/adult-protective-services</a></span>
                                    </li> */}
                        {/* <li>
                                        <i><img src="assets/icon/pricing.png" /></i>
                                        <span>$$$</span>
                                    </li> */}
                    </ul>
                </div>
            </div>




        </div>

        //         </div>
        //     </div>
        // </section>

    );
}

export default SearchWizardResultPanelCondensed;
