// export const URL_ROOT = 'http://localhost:3030/';
// export const URL_ROOT = 'http://3.95.74.235:3031/';
// FOR PROD
//bk export const URL_ROOT = 'http://3.225.95.108:3000/';

export const URL_ROOT = 'https://findservicesapi.empowerline.org/';

export const URL_GET_LAT_LNG = "v1/user-starting-point-lat-lng";
export const URL_GET_MICROSITE_DETAILS = "v1/fetch-single-microsite";
export const SAVE_SEARCH_KEY = "v1/save-microsite-search-key";
export const SEND_FEEDBACK_FOR_CHANGES_SERVICE = "v1/send-feedback-to-changes-service";




export const CATEGORIES = "v1/categories";
export const FILL_CATEGORIES = "FILL_CATEGORIES";
export const SET_GLOSSARY_SERVICE = 'SET_GLOSSARY_SERVICE'

export const SERVICE_CODES_BY_CATEGORY = "v1/service_codes_by_category/";
export const URL_SEARCH = "v1/user-search";
export const UPDATE_SELECTED_OPTIONS = "UPDATE_SELECTED_OPTIONS";
export const CATEGORY = "v1/category/";
export const FILL_SERVICE_CODES = "FILL_SERVICE_CODES";
export const FETCH_RESULTS = 'FETCH_RESULTS';
export const RESTE_SEARCH = 'RESTE_SEARCH';

export const REQUEST_RESULTS = 'REQUEST_RESULTS';
export const SET_SERVICE_PROVIDER_NAME = 'SEARCH.SET_SERVICE_PROVIDER_NAME';
export const FETCH_COUNTY_OPTIONS = 'Search/FETCH_COUNTY_OPTIONS'
export const URL_COUNTIES = "v1/counties";
export const SET_COUNTY_OF_LOCATION = 'Search/SET_COUNTY_OF_LOCATION'
export const SET_PRICE = 'SET_PRICE';
export const SET_STARTING_POINT = 'SET_STARTING_POINT'
export const SET_END_POINT = 'SET_END_POINT'
export const STARTING_POINT_LAT_LNG = 'STARTING_POINT_LAT_LNG'

export const SET_STRAIGHT_LIINE_DISTANCE = "SET_STRAIGHT_LIINE_DISTANCE";
export const URL_SEND_MESSAGE_TO_ADMIN = "v1/email/send-usermessage-to-admin";
export const URL_SHARE_RESULE_LINK = "v1/email/share-resule-link";

// export const HELP_EMAIL = ['JBeamer@atlantaregional.org']
export const HELP_EMAIL = ['agingtech@atlantaregional.org', 'DSonnier-Brown@atlantaregional.org', 'JBeamer@atlantaregional.org']
export const SHARE_FROM = ['agingtech@atlantaregional.org']

export const SET_LOGO_BANNER = 'SET_LOGO_BANNER';

export const SET_SHARE_QUERY = 'SET_SHARE_QUERY';









