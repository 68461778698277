import React, { useState, useEffect, useCallback } from 'react';
import { Route, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ServiceComponents from '../../components/search/Service'
import SearchResult from './SearchResult'
import MapWithAMarker from './Map'
import queryString from 'query-string'

import * as searchActions from './Action';
import _ from 'lodash'
import Select from 'react-select';
/* eslint-disable no-undef */
import { DirectionsRenderer, GoogleMapLoader, GoogleMap, Marker, Geocode, Polyline, InfoWindow } from 'react-google-maps';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/core";


import HelpMessageBoard from "./HelpMessageBoard";
import ShareWith from "./ShareWith";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const Service = (props) => {
    const values = queryString.parse(window.location.search)

    const dispatch = useDispatch();
    const search = useSelector(state => state.search);
    const query = useSelector(state => state.search.query);
    const shareQuery = useSelector(state => state.search.shareQuery);
    const shareid = useSelector(state => state.search.shareid);

    const [direction, setDirections] = useState(null);
    const [straightLineDistance, setStraightLineDistance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [help, setHelp] = useState(false);
    const [isOpen, setisOpen] = useState(false);
    const [markerAddress, setMarkerAddress] = useState('');
    const [sid, setSid] = useState();
    const [shareLink, setLink] = useState('');


    console.log('values share', search.share)


    console.log('start_point_lat', search.start_point_lat + 'lng', search.start_point_lng)
    let results = search.search_results
    let markers = [];
    let cleaned_data = []


    useEffect(() => {
        if (search.search_results && search.search_results.length == 0) {
            props.history.push({
                pathname: '/'
            });
        }
    }, [search]);

    useEffect(() => {

        const directionsService = new google.maps.DirectionsService();
        console.log('search.provider_lat', search.provider_lat)

        if (search.start_point_lat) {
            // const directionsService = new google.maps.DirectionsService();
            let latitude = search.start_point_lat
            let longitude = search.start_point_lng

            let provider_lat = search.provider_lat
            let provider_lng = search.provider_lng
            const origin = new google.maps.LatLng(latitude, longitude)
            const destination = new google.maps.LatLng(provider_lat, provider_lng)
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {

                        setDirections(result)
                        // this.setState({
                        //     directions: result,
                        // });
                    } else {
                        console.error(`error fetching directions ${result}`);
                    }
                }

            );
            const route = [
                origin,
                destination
            ];
            const polyline = new google.maps.Polyline({
                path: route,
            });
            const lengthInMeters = google.maps.geometry.spherical.computeLength(polyline.getPath());
            const miles = Math.round(lengthInMeters / 1000 * 0.6214 * 10) / 10;
            setStraightLineDistance(miles)
            // this.props.setStraightLineDistance(miles)
        }


    }, [search.start_point_lat, search.provider_lat])


    const onMarkerClick = (service) => {
        setisOpen(true)
        setSid(service.id)

        let info = `${service.addresses[0].address_line_1},${service.addresses[0].city},${service.addresses[0].zip} `
        setMarkerAddress(info)

    };

    const onToggleOpen = (evt) => {
        setisOpen(false)
    };





    if (results && results.length > 0) {
        markers = results.map(function (service) {
            return (
                // <Marker
                //     defaultPosition={{ lat: service.latitude, lng: service.longitude }}
                //     // icon={icon}
                //     title={service.org_name}
                //     key={service.id}
                //     onClick={onMarkerClick.bind(this, service)}

                // />

                <Marker
                    defaultPosition={{ lat: service.latitude, lng: service.longitude }}
                    // icon={icon}
                    title={service.org_name}
                    key={service.id}
                    onClick={onMarkerClick.bind(this, service)}

                >
                    {isOpen && sid === service.id && <InfoWindow onCloseClick={onToggleOpen}>
                        <>
                            <div>{service.org_name}</div>
                            <div>{markerAddress}</div>
                        </>
                        {/* <span>{markerAddress}</span> */}
                    </InfoWindow>}
                </Marker>

            )
        });

        cleaned_data = results.map(function (provider) {
            return {
                label: provider.org_name + " (" + provider.service_code.description,
                value: provider.id,
                provider_lat: provider.latitude,
                provider_lng: provider.longitude
            }
        })
    }
    let _options = _.sortBy(cleaned_data, 'label')

    const startingPoint = (field) => {
        dispatch(searchActions.setStartingPoint(field.target.value))
    }

    const handleSelectChangege = (e) => {
        // console.log('handleSelectChange',e)
        // this.props.setEndPointPoint(e);
        dispatch(searchActions.setEndPointPoint(e));
        dispatch(searchActions.getStartingPointLatLongFromAddress(search.startpoint));
        // this.props.getStartingPointLatLongFromAddress(this.props.startpoint)

    }
    // if (straightLineDistance) {
    //     const straightLineDistanceMiles = <div style={{ fontSize: 25 }}><label className='control-label'>Distance: </label>
    //         <span style={{ color: 'green' }}> {distance} Miles.</span></div>
    // }

    const straightLineDistanceMiles = () => {
        if (straightLineDistance) {
            return <div style={{ fontSize: 25 }}><label className='control-label'>Distance: </label>
                <span style={{ color: 'green' }}> {straightLineDistance} Miles.</span></div>
        }

    }
    const handleGoBack = () => {
        dispatch(searchActions.resetSearch());
        setStraightLineDistance(null)
        setDirections(null)

        if (search.share) {
            props.history.push({
                pathname: '/'

            })
        } else {
            props.history.goBack();

        }



        // console.log('shareQuery', shareQuery)
        // console.log('shareid', shareid)


        // props.history.push({
        //     pathname: '/?id=9360&/testabhi'

        // })

    }

    const openHelpModel = () => {
        setHelp(!help)
        console.log('shareQuery', shareQuery)

        let link = `https://findservicesapi.empowerline.org/?shareid=${shareid}`
        setLink(link)
        console.log('link', link)
    }

    const closeModal = () => {
        setHelp(!help)
    }

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    return (
        <>
            <section class="main-form-sec">
                <div class="container">
                    <div class="search-top-sec">
                        <form action="">

                            <div class="row align-items-center">



                                <div class="col-lg-2">
                                    <h2 class="title-blue">Mapped Locations</h2>
                                </div>
                                <div class="col-lg-4">
                                    <label for="">Start Point</label>
                                    <input type="text" className='form-control' onChange={startingPoint.bind(this)} placeholder="Choose starting point" />
                                </div>
                                <div class="col-lg-4">
                                    <label for="">End point</label>
                                    {/* <div class="select"> */}
                                    <Select
                                        options={_options}
                                        onChange={handleSelectChangege.bind(this)}
                                    // onChange={props.handleSelectPrice}
                                    // value={selectedPriceRange}

                                    />
                                    {/* </div> */}
                                </div>
                                <div>
                                    {straightLineDistanceMiles()}
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

                <div className="sweet-loading" style={{
                    position: 'absolute',
                    left: '50%',
                    marginTop: '0%',
                    zIndex: 5
                }}>
                    <ScaleLoader
                        css={override}
                        size={100}
                        color={"#eebd60"}
                        loading={results && results.length > 0 ? false : true}

                    />
                </div>
            </section>


            <div>

                <MapWithAMarker
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBb3F_BDIggsttZOaS87O5L0bcRN3_R1Qg&v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    markers={markers}
                    direction={direction}
                    straightLineDistance={straightLineDistance}
                />
                <div class="row align-items-center">
                    <div className="form-btn mt-4">
                        <button
                            className="btn btn-search"
                            onClick={handleGoBack}
                        >
                            Back
                        </button>

                    </div>
                    {shareid != null && <div className="form-btn mt-4">
                        <button
                            className="btn btn-search"
                            onClick={openHelpModel}
                        >
                            Share with
                        </button>

                    </div>}
                </div>
                {
                    help && <ShareWith closeModal={closeModal} shareLink={shareLink} />
                }

                {/* <ServiceComponents /> */}


                {!help && <SearchResult />}
                <div className="form-btn right-float">
                    <button
                        className="btn btn-search"
                        onClick={handleScrollTop}
                    >
                        <span>Go to top </span>
                        <i class="fa fa-arrow-up" aria-hidden="true"></i>

                    </button>

                </div>

            </div>
        </>
    );
}

export default Service;
