// import * as type from '../../../constants/ActionTypes'
import * as type from '../../../constant/service/ActionTypes'

import util from 'util'
// import _ from 'lodash'
import axios from 'axios';


export function getCategories() {
    let promise = new Promise(function (resolve, reject) {
        const url = `${type.URL_ROOT}${type.CATEGORIES}`;

        let cleaned_data = [];
        console.log('url', url)

        axios.get(url)
            .then(response => {
                cleaned_data = response.data.categories.map(function (category) {
                    return {
                        label: category.description,
                        value: category.id,
                        id: category.id
                    }
                })

                resolve(cleaned_data);
            })
            .catch(response => {
                // handleAuthError(response);
                console.log('inside the error response: ', util.inspect(response));
                reject('Error fetching categories');
            })
    })
    return (promise);
}


/**
* Function to fetch all service codes for use in select
*
*/
// export function getServiceCodes(category) {
//     let promise = new Promise(function (resolve, reject) {
//         const service_url = `${type.URL_ROOT}${type.SERVICE_CODES}`;
//         // console.log("What is service from query : ", util.inspect(service_url));
//         let cleaned_data = []

//         axios.get(service_url)
//             .then(response => {
//                 cleaned_data = response.data.service_categories.map(function (service_code) {
//                     return {
//                         label: service_code.description,
//                         value: service_code.id,
//                         definition: service_code.definition,
//                         category_id: service_code.service_category_id
//                     }
//                 })

//                 resolve(cleaned_data);
//             })
//     }).catch(response => {
//         handleAuthError(response);
//         console.log('inside the error response: ', util.inspect(response));
//         reject('Error fetching service codes');
//     })

//     return (promise);
// }


export function getCounties() {
    let promise = new Promise((resolve, reject) => {
        const url = `${type.URL_ROOT}${type.URL_COUNTIES}`

        axios.get(url)
            .then(response => {
                if (response.data) {
                    resolve(response.data.counties)
                }
            })
            .catch(response => {
                // handleAuthError(response);
                console.log('inside the error response: ', util.inspect(response))
                reject('Error fetching counties')
            })
    })
    return promise
}


export function getServiceCodesByCategory(category_id) {
    let promise = new Promise(function (resolve, reject) {
        const url = `${type.URL_ROOT}${type.SERVICE_CODES_BY_CATEGORY}${category_id}`;

        // console.log("attempting to call url : ", url);
        let cleaned_data = [];
        axios.get(url)
            .then(response => {
                if (response && response.data && response.data.length > 0) {
                    cleaned_data = response.data.map(function (service_code) {
                        return {
                            label: service_code.description,
                            value: service_code.id,
                            definition: service_code.definition,
                            category_id: category_id
                        }
                    })
                    resolve(cleaned_data);
                } else {
                    resolve([]);
                }
            })
    }).catch(response => {
        // handleAuthError(response);
        console.log('inside the error response: ', util.inspect(response));
        // reject('Error fetching service cdoes by category');
    })

    return (promise);
}

export function getCategoryById(category_id) {
    let promise = new Promise(function (resolve, reject) {
        const url = `${type.URL_ROOT}${type.CATEGORY}${category_id}`;

        // console.log("attempting to call url : ", url);
        let cleaned_data = [];
        axios.get(url)
            .then(response => {
                resolve(response);
            })
    }).catch(response => {
        //handleAuthError(response);
        console.log('inside the error response: ', util.inspect(response));
        //reject('Error fetching service cdoes by category');
    })

    return (promise);
}



