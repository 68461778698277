
import React, { useState, useEffect, useCallback } from 'react';
import { Link, browserHistory } from 'react-router';
import * as actions from './Action';
import Select from 'react-select';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, RichUtils, ContentState, convertFromRaw, SelectionState, convertToRaw } from 'draft-js';
import './style.css';
import * as mailType from '../../constant/service/ActionTypes'
import * as type from '../../constant/service/ActionTypes'
import axios from 'axios';

const editorStyle = {
    minHeight: ' 150px',
    border: '1px solid #ccc',
    padding: '8px'
}
const ShareWith = (props) => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [content, setContent] = useState(<h2></h2>);
    const [subject, setSubject] = useState(null);
    const [to, setTo] = useState(null);
    const [message, setMessage] = useState(null);

    const onEditorStateChange = (editorState) => {
        const rawContentState = editorState.getCurrentContent();
        const editorContentRaw = convertToRaw(rawContentState);
        const html = draftToHtml(editorContentRaw);
        setContent(html)
        setEditorState(editorState)
    };
    console.log('content', content)
    console.log('editorState', editorState)
    const renderMessage = () => {
        if (message == 'fail') {
            return (
                <div className="alert alert-danger alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <span>Share Link not send please check your connection</span>
                </div>
            );
        }
        else if (message == 'sucess') {
            return (
                <div className="alert alert-success alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <span> Share Link sent</span>

                </div>
            );
        }
    }


    const handleSend = () => {

        const url = `${type.URL_ROOT}${type.URL_SHARE_RESULE_LINK}`;
        // console.log('>>> url to create user:', url,userSend);
        console.log('>>> url :', url);

        let bodyParam = {
            subject: subject,
            message: props.shareLink,
            to: to,
            from: mailType.SHARE_FROM,

        }
        console.log('>>> bodyParam:', bodyParam);

        axios.post(url, bodyParam)
            .then(response => {
                if (response.status == 200) {
                    console.log('sucess', response)
                    setMessage('sucess')
                } else {
                    setMessage('fail')

                }

            })
            .catch(response => {
                console.log("Crap we are in the catch!");
                console.log("Error : " + response);
                setMessage('fail')
            });

    }

    const resetMessageBoard = () => {
        // this.props.resetMessageBoard();
    }



    return (
        <div className="row">
            <div className="col-sm-12" id="message-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel">Share - Share Result Link</h4>
                        <button
                            className="btn btn-link"
                            onClick={() => props.closeModal()}
                        >
                            Close
                            {/* <i class="fa fa-close" /> */}
                        </button>
                        {renderMessage()}
                        {/*     {this.renderMessageEditorMessage()} */}
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="email">Share With (example@example.com)</label>
                            <input type="text" placeholder="Enter email id" onChange={(e) => setTo(e.target.value)} value={to} className="form-control" />
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="subject">Subject	</label>
                            <input type="text" onChange={(e) => setSubject(e.target.value)} value={subject} className="form-control" />

                        </div> */}
                        {/* <div className="form-group">
                            <Editor
                                initialEditorState={editorState}
                                placeholder="Enter Message...."
                                onEditorStateChange={onEditorStateChange}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorStyle={editorStyle}
                            />
                        </div> */}
                        <div className="col-md-4" style={{ marginTop: '-8px' }}>
                            <button onClick={handleSend} className="btn btn-primary btn-sm">Send</button>&nbsp;&nbsp;
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-sm-12">
            </div>
        </div>
    );
}



export default ShareWith