import {
    FILL_CATEGORIES,
    FILL_SERVICE_CODES,
    UPDATE_SELECTED_OPTIONS,
    SET_SERVICE_PROVIDER_NAME,
    FETCH_COUNTY_OPTIONS,
    SET_COUNTY_OF_LOCATION,
    SET_PRICE,
    FETCH_RESULTS,
    SET_STARTING_POINT,
    SET_END_POINT,
    STARTING_POINT_LAT_LNG,
    SET_STRAIGHT_LIINE_DISTANCE,
    RESTE_SEARCH,
    SET_GLOSSARY_SERVICE,
    SET_SHARE_QUERY

} from '../../constant/service/ActionTypes'

const initialState = {
    category_options: {},
    serviceDefenation: {},
    query: { limit: [10], offset: [0], status: 'false' },
    search_results: [],
    meta: [],
    narrow_counts: [],
    isLoading: false,
    selected_services: [],
    selected_categories: [],
    server_error: null,
    distance: null,
    provider_lat: null,
    provider_lng: null,
    shareQuery: null,
    shareid: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case FILL_CATEGORIES:
            return {
                ...state,
                category_options: action.category_options
            };
        case SET_GLOSSARY_SERVICE:
            return {
                ...state,
                serviceDefenation: action.serviceDefenation
            };
        case FILL_SERVICE_CODES:
            return {
                ...state, service_code_options: action.service_code_options
            };
        case UPDATE_SELECTED_OPTIONS: {
            let object_to_set = { ...state };

            if (action.field === 'service') {
                object_to_set = { ...state, selected_services: action.selection, query: action.query }
            }

            if (action.field === 'category') {
                object_to_set = { ...state, selected_categories: action.selection, query: action.query }
            }
            return object_to_set;
        }

        case SET_SERVICE_PROVIDER_NAME: {
            return { ...state, entered_service_provider_name: action.entered_service_provider_name, query: action.query }
        }
        case FETCH_COUNTY_OPTIONS:
            return { ...state, county_options: action.payload }
        case SET_COUNTY_OF_LOCATION:
            return { ...state, county_of_location: action.payload, query: action.query }
        case SET_PRICE:
            return { ...state, selectedPrice: action.payload, query: action.query }

        case FETCH_RESULTS: {
            if ((typeof action.matched_results === 'string' && action.matched_results.startsWith('<html>')) || (action.matched_results.data && action.matched_results.data.success === false)) {
                return Object.assign({}, state, {
                    isLoading: false,
                    meta: undefined,
                    narrow_counts: undefined,
                    search_results: undefined,
                    offset: action.offset,
                    selected_page: action.selected_page,
                    pageNum: pageNum,
                    referral: null,
                    cartreferral: null,
                    server_error: 'Error: request took more than 30 seconds',
                    email_sent: null,
                    email_error: false,
                    cart_email_sent: null
                });
            }

            let pageNum = 0;
            if (action.matched_results.meta) pageNum = action.matched_results.meta.narrowed_count / Number(action.matched_results.meta.limit);

            return Object.assign({}, state, {
                isLoading: false,
                meta: action.matched_results.meta,
                narrow_counts: action.matched_results.narrow_counts,
                search_results: action.matched_results.services,
                offset: action.offset,
                selected_page: action.selected_page,
                pageNum,
                referral: null,
                cartreferral: null,
                server_error: null,
                email_sent: null,
                email_error: false,
                cart_email_sent: null,
                share: action.share
            });
        }

        case RESTE_SEARCH: {
            return {
                ...state,
                entered_service_provider_name: '',
                query: {},
                selectedPrice: '',
                county_of_location: '',
                // selected_services: '',
                // selected_categories: '',
                isLoading: false,
                meta: undefined,
                narrow_counts: undefined,
                search_results: undefined,
                selected_services: [],
                selected_categories: [],
                distance: null,
                provider_lat: null,
                provider_lng: null,
                start_point_lat: null,
                start_point_lng: null,
                shareQuery: null,
                shareid: null,
            }
        };
        case SET_STARTING_POINT: {
            return { ...state, startpoint: action.value }
        };
        case SET_END_POINT: {
            return { ...state, endpointselectedoption: action.value, provider_lat: action.value.provider_lat || '', provider_lng: action.value.provider_lng || "" }
        };
        case STARTING_POINT_LAT_LNG: {
            return { ...state, start_point_lat: action.payload.latitude, start_point_lng: action.payload.longitude }
        };
        case SET_STRAIGHT_LIINE_DISTANCE: {
            return { ...state, distance: action.distance }
        };
        case SET_SHARE_QUERY: {
            return { ...state, shareid: action.id, shareQuery: action.shareQuery }
        };

    }
    return state;
}