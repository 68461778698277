import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import {
  createStore,
  combineReducers, applyMiddleware,
} from 'redux';
import { Provider, connect } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'remote-redux-devtools';
import searchReducers from './store/reducers/search';
import commonReducers from './store/reducers/common';

import Search from './containers/search/Search'
import Service from './containers/search/Service'

const rootReducer = combineReducers({
  search: searchReducers,
  common: commonReducers

});

const composeEnhancers = composeWithDevTools({ realtime: true, port: 8000 });
const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(ReduxThunk),
));
ReactDOM.render(

  // <React.StrictMode>
  <Provider store={store}>

    <BrowserRouter>

      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
  ,
  document.getElementById('root')

);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
