import axios from 'axios';

import util from 'util'
import _ from 'lodash'
import * as search_module from './modules/search_module';
import * as type from '../../constant/service/ActionTypes'


const category = [
    {
        id: 1,
        label: 'Home',
        value: 1
    },
    {
        id: 2,
        label: 'Health',
        value: 2
    },
    {
        id: 3,
        label: 'Community',
        value: 3
    },
    {
        id: 4,
        label: 'Assistance and Benefits',
        value: 4
    },
    {
        id: 5,
        label: 'Caregiver',
        value: 5
    },
    {
        id: 6,
        label: 'Transportation',
        value: 6
    }

]
const service = {
    home: [

        {
            "id": 260,
            "code": "PCH",
            "description": "Personal Care Homes",
            "service_category_id": 43,
            "updated_at": "2016-09-21T16:19:12.625Z",
            "created_at": "2016-09-21T16:19:12.625Z",
            "deleted_at": null,
            "definition": " Personal Care Homes provide housing in a group setting for elderly or disabled individuals. In these facilities, the owner or manager provides or arranges for the provision of housing, food service, and one or more personal services. Individuals residing in these facilities must be ambulatory with or without assistive devices. Personal services shall include, but are not limited to, individual assistance with or supervision of self-administered medication, assistance with ambulation and transfers, essential activities or daily living such as eating, bathing, grooming, dressing and toileting, and 24-hour responsibility for the well-being of the residents. Each home shall provide individual residents with protective care and watchful oversight. Related Terms: Assisted Living facilities, adult foster care, homes\r\n"
        },
        {
            "id": 5,
            "code": "ADC",
            "description": "Adult Day Care",
            "service_category_id": 3,
            "updated_at": "2016-09-21T16:19:12.122Z",
            "created_at": "2016-09-21T16:19:12.122Z",
            "deleted_at": null,
            "definition": "Adult Day Care Programs are community-based programs designed to meet the needs of adults with functional impairments. Through a structured and comprehensive program a variety of health, social and related support services are offered in a supervised protective setting during some portion of a twenty-four hour day. The Georgia Department of Human Services licenses adult day care programs. Related terms: Adult Day Health, Medicaid Waiver Programs, Respite Care, Out of Home Respite"
        },
        {
            "id": 2,
            "code": "AAA",
            "description": "Area Agencies on Aging",
            "service_category_id": 41,
            "updated_at": "2016-09-21T16:19:12.114Z",
            "created_at": "2016-09-21T16:19:12.114Z",
            "deleted_at": null,
            "definition": "Area Agencies on Aging are public or private nonprofit agencies designated by the state to address the needs and concerns of all older Americans at the local level. Reltated term: AAA, Aging Services, Title III"
        },
        {
            "id": 42,
            "code": "CCR",
            "description": "Continuing Care Retirement Campuses",
            "service_category_id": 28,
            "updated_at": "2016-09-21T16:19:12.196Z",
            "created_at": "2016-09-21T16:19:12.196Z",
            "deleted_at": null,
            "definition": "Continuing Care Retirement Communities are residential facilities, also called “lifecare,” which offer a continuum of care designed to meet residents' needs as they change. Under a contractual agreement, various types of accommodations and service/amenities can be arranged. Residents may be able to move from independent living to personal care/assisted living to nursing home care, depending upon the scope of the contract. These facilities might also offer organized recreational and social activities, transportation, and personal conveniences such as beauty/barber shops, banking privileges and convenience stores. A sizable entrance fee may be required and can range to over $200,000. In addition to the entrance fee, a monthly maintenance fee may also be required. These continuing care developments are not specifically defined or regulated in Georgia. Related terms: housing, CCRC\r\n"
        }, {
            "id": 261,
            "code": "PCHAL",
            "description": "Assisted Living Communities",
            "service_category_id": 43,
            "updated_at": "2016-09-21T16:19:12.627Z",
            "created_at": "2016-09-21T16:19:12.627Z",
            "deleted_at": null,
            "definition": "Assisted Living Communities are residential communities that are licensed by the Georgia Department of Community Health, Healthcare Facility Regulation (HFR). Assisted Living Communities require a separate level of licensure from HFR. These communities have a capacity of 25 or more residents and provide assisted living care to adults who require varying degrees of assistance with the activities of daily living but who do not require continuous medical or nursing care. “Assisted living care” means the specialized care and services provided by an assisted living community which includes the provision of personal services, the administration of medications by a certified medication aide and the provision of assisted self-preservation which is the capacity of a resident to be evacuated from an assisted living community to a designated point of safety within an established period of time as determined by the Office of Fire Safety Commissioner. The physical structure must have handrails, doorways and corridors which accommodate mobility devices, such as walkers, wheel chairs and crutches or canes in addition to other structural requirements in the state regulations that are necessary for resident comfort and safety. "
        }, {
            "id": 242,
            "code": "NH",
            "description": "Nursing Homes",
            "service_category_id": 39,
            "updated_at": "2016-09-21T16:19:12.591Z",
            "created_at": "2016-09-21T16:19:12.591Z",
            "deleted_at": null,
            "definition": "Nursing homes are inpatient health care facilities that provide nursing and custodial care over an extended period of time for individuals who need 24-hour care and supervision according to applicable laws and regulations. Related Terms: Long Term Care "
        },
        {
            "id": 355,
            "code": "UD",
            "description": "Utility Discount Programs",
            "service_category_id": 17,
            "updated_at": "2016-09-21T16:19:12.809Z",
            "created_at": "2016-09-21T16:19:12.809Z",
            "deleted_at": null,
            "definition": "Utility Discounts - Utility Discounts Programs offer special services which support individuals' ability to make their utility payments. Programs may require that people be in a certain income bracket, have limited resources and/or be a certain age. Related Terms: Senior Discounts, LIHEAP "
        },
        {
            "id": 180,
            "code": "HHA",
            "description": "Home Health Agencies",
            "service_category_id": 27,
            "updated_at": "2016-09-21T16:19:12.471Z",
            "created_at": "2016-09-21T16:19:12.471Z",
            "deleted_at": null,
            "definition": "Home Health Agencies are programs that provide short-term skilled nursing and/or rehabilitative services to homebound persons. Medicare, Medicaid, Veteran's Administration or private insurance may cover these services. Medicare will pay 100% for services when the doctor orders skilled, intermittent nursing care or rehabilitation services. Medicare will also pay 100% when the doctor orders the services of a part-time home health aide in conjunction with those skilled services. The home health aide will assist with bathing and dressing of the patient, and light housekeeping in the area the patient occupies. These personnel remain in the patient's home only long enough to perform their specific tasks. The Georgia Department of Human Resources licenses home health agencies. See also Home-Based Services, Home Care Providers, Home Health Care."
        },
        {
            "id": 195,
            "code": "HSPC",
            "description": "Hospice Care",
            "service_category_id": 22,
            "updated_at": "2016-09-21T16:19:12.503Z",
            "created_at": "2016-09-21T16:19:12.503Z",
            "deleted_at": null,
            "definition": "Hospice Care - Hospice Care are programs that provide a full range of palliative and supportive services for terminally ill people who are in the final stages of their illnesses and for their families. Services may include medical care, pain and symptom management, home nurse visitation, care management, counseling and bereavement services for the patient and members of the family. A hospice may be a freestanding facility, a unit in a hospital or other institution, a program of a hospital, agency or institution, or service delivered in the home or residential institution. Related Terms: palliative care, pain management, end of life care\r\n\r\n"
        },
        {
            "id": 204,
            "code": "IRCN",
            "description": "Ind Retirement Comm - Nonsubsidized",
            "service_category_id": 28,
            "updated_at": "2016-09-21T16:19:12.520Z",
            "created_at": "2016-09-21T16:19:12.520Z",
            "deleted_at": null,
            "definition": "Independent Retirement Communities/NonSubsidized are residential facilities, apartments and/or planned communities that offer a variety of choices appropriate for independent older adults and are offered at market rate. Different types of independent living include senior adult apartment communities; and retirement communities that offer an individual the opportunity to buy or lease in a community especially designed for older persons. Services may be available including on site activity program or clubhouse, transportation, housekeeping and meals in a communal dining room. Minimum age for residency is either 55 or 62 years of age depending on the community funding and/or zoning requirements. These communities are fee for service. Related terms: housing"
        },
        {
            "id": 203,
            "code": "IRC",
            "description": "Ind Retirement Comm - Subsidized",
            "service_category_id": 28,
            "updated_at": "2016-09-21T16:19:12.518Z",
            "created_at": "2016-09-21T16:19:12.518Z",
            "deleted_at": null,
            "definition": "Independent Retirement Communities/Subsidized are residential facilities, apartments and/or planned communities that offer a variety of choices appropriate for independent older adults where residents usually pay 30% of their adjusted gross income toward the rent. These communities may have a portion of their units where fair market rent is charged. There may be on site activities or other amenities available. Related terms: housing "
        },
        {
            "id": 116,
            "code": "ECW",
            "description": "Energy Conservation/Weatherization",
            "service_category_id": 29,
            "updated_at": "2016-09-21T16:19:12.349Z",
            "created_at": "2016-09-21T16:19:12.349Z",
            "deleted_at": null,
            "definition": "Energy Conservation and Weatherization are programs that provide assistance in the form of labor and supplies, cash or tax rebates or consultation services for homeowners who want to make home improvements that are designed to reduce energy consumption, or for people who need to install weather stripping, insulation, insulation wrap for water tanks, double glazed windows, storm windows, storm shutters or other approved materials for reducing energy consumption or protecting the home from the elements and who qualify for this service on the basis of income, age or disability. Related terms: energy savings\r\n"
        },
        {
            "id": 186,
            "code": "HM",
            "description": "Home Modifications",
            "service_category_id": 29,
            "updated_at": "2016-09-21T16:19:12.486Z",
            "created_at": "2016-09-21T16:19:12.486Z",
            "deleted_at": null,
            "definition": "Home Modifications are programs that pay for or provide assistance in the form of labor and supplies for disabled people who need to install ramps, widen doorways, install grab bars in showers and bathrooms, lower kitchen and other cabinets, or make other modifications in their homes to make them accessible. Also included are programs that assess the accessibility of homes of people who have disabilities and make recommendations regarding necessary modifications as well as private pay resources that provide the above indicated services. Related Terms: Accessibility, Wheelchair Accessible \r\n"
        },
        {
            "id": 234,
            "code": "MTR",
            "description": "Maintenance/Repair",
            "service_category_id": 29,
            "updated_at": "2016-09-21T16:19:12.576Z",
            "created_at": "2016-09-21T16:19:12.576Z",
            "deleted_at": null,
            "definition": "Maintenance/Repair Programs provide assistance in the form of labor and supplies for people who need to make essential repairs to their homes in order to eliminate health or safety hazards and who qualify for the service on the basis of income, age or disability. Related terms: roofs, plumbing, carpentry, electrical "
        },
        {
            "id": 280,
            "code": "PR",
            "description": "Parks & Recreation",
            "service_category_id": 35,
            "updated_at": "2016-09-21T16:19:12.665Z",
            "created_at": "2016-09-21T16:19:12.665Z",
            "deleted_at": null,
            "definition": "Parks and Recreation Programs provide facilities and a variety of organized recreational activities for people of all ages.\r\n\r\n\r\n"
        },
        {
            "id": 176,
            "code": "HDM",
            "description": "Home Delivered Meals",
            "service_category_id": 40,
            "updated_at": "2016-09-21T16:19:12.464Z",
            "created_at": "2016-09-21T16:19:12.464Z",
            "deleted_at": null,
            "definition": "Home-Delivered Meals are programs that prepare and/or deliver meals five or more days per week to homebound elderly or disabled adults. Modified meals, designed to meet special dietary needs, are available in the home-delivered meal programs with a physician's prescription. Related Term: Meals on Wheels\r\n"
        },
        {
            "id": 172,
            "code": "HDM",
            "description": "Home Care Provider/ Personal Care",
            "service_category_id": 172,
            "updated_at": "2016-09-21T16:19:12.464Z",
            "created_at": "2016-09-21T16:19:12.464Z",
            "deleted_at": null,
            "definition": "Home Care Provider/ Personal Care"
        },
    ],
    Health: [
        {
            "id": 231,
            "description": "Intake and Screening - Mental Health",
            "value": 231,
            "definition": "MH Intake and Screening are screenings that are public funded programs that evaluate individuals for psychiatric emergencies, mental health disorders, drug abuse, and/or alcohol abuse and triage them to appropriate treatment programs within their system or other public programs or make referrals to other appropriate resources/agencies. Related terms: behavioral health, substance abuse, addictive disease, Psychiatric, mental health \r\n\r\n",
            "category_id": 37
        },
        {
            "id": 382,
            "code": "XVA",
            "description": "Veterans Information and Assistance",
            "service_category_id": 54,
            "updated_at": "2016-09-21T16:19:12.862Z",
            "created_at": "2016-09-21T16:19:12.862Z",
            "deleted_at": null,
            "definition": "Programs offered by the United States Department of Veterans Affairs or under contract to the agency, affiliated non-profit organizations or other state or local governmental entities that provide information and linking to resources on a variety of veterans specific services.\r\n"
        },
        {
            "id": 2,
            "code": "AAA",
            "description": "Area Agencies on Aging",
            "service_category_id": 41,
            "updated_at": "2016-09-21T16:19:12.114Z",
            "created_at": "2016-09-21T16:19:12.114Z",
            "deleted_at": null,
            "definition": "Area Agencies on Aging are public or private nonprofit agencies designated by the state to address the needs and concerns of all older Americans at the local level. Reltated term: AAA, Aging Services, Title III"
        },
        {
            "id": 260,
            "code": "PCH",
            "description": "Personal Care Homes",
            "service_category_id": 43,
            "updated_at": "2016-09-21T16:19:12.625Z",
            "created_at": "2016-09-21T16:19:12.625Z",
            "deleted_at": null,
            "definition": " Personal Care Homes provide housing in a group setting for elderly or disabled individuals. In these facilities, the owner or manager provides or arranges for the provision of housing, food service, and one or more personal services. Individuals residing in these facilities must be ambulatory with or without assistive devices. Personal services shall include, but are not limited to, individual assistance with or supervision of self-administered medication, assistance with ambulation and transfers, essential activities or daily living such as eating, bathing, grooming, dressing and toileting, and 24-hour responsibility for the well-being of the residents. Each home shall provide individual residents with protective care and watchful oversight. Related Terms: Assisted Living facilities, adult foster care, homes\r\n"
        },
        {
            "id": 261,
            "code": "PCHAL",
            "description": "Assisted Living Communities",
            "service_category_id": 43,
            "updated_at": "2016-09-21T16:19:12.627Z",
            "created_at": "2016-09-21T16:19:12.627Z",
            "deleted_at": null,
            "definition": "Assisted Living Communities are residential communities that are licensed by the Georgia Department of Community Health, Healthcare Facility Regulation (HFR). Assisted Living Communities require a separate level of licensure from HFR. These communities have a capacity of 25 or more residents and provide assisted living care to adults who require varying degrees of assistance with the activities of daily living but who do not require continuous medical or nursing care. “Assisted living care” means the specialized care and services provided by an assisted living community which includes the provision of personal services, the administration of medications by a certified medication aide and the provision of assisted self-preservation which is the capacity of a resident to be evacuated from an assisted living community to a designated point of safety within an established period of time as determined by the Office of Fire Safety Commissioner. The physical structure must have handrails, doorways and corridors which accommodate mobility devices, such as walkers, wheel chairs and crutches or canes in addition to other structural requirements in the state regulations that are necessary for resident comfort and safety. "
        },
        {
            "id": 42,
            "code": "CCR",
            "description": "Continuing Care Retirement Campuses",
            "service_category_id": 28,
            "updated_at": "2016-09-21T16:19:12.196Z",
            "created_at": "2016-09-21T16:19:12.196Z",
            "deleted_at": null,
            "definition": "Continuing Care Retirement Communities are residential facilities, also called “lifecare,” which offer a continuum of care designed to meet residents' needs as they change. Under a contractual agreement, various types of accommodations and service/amenities can be arranged. Residents may be able to move from independent living to personal care/assisted living to nursing home care, depending upon the scope of the contract. These facilities might also offer organized recreational and social activities, transportation, and personal conveniences such as beauty/barber shops, banking privileges and convenience stores. A sizable entrance fee may be required and can range to over $200,000. In addition to the entrance fee, a monthly maintenance fee may also be required. These continuing care developments are not specifically defined or regulated in Georgia. Related terms: housing, CCRC\r\n"
        }, {
            "id": 242,
            "code": "NH",
            "description": "Nursing Homes",
            "service_category_id": 39,
            "updated_at": "2016-09-21T16:19:12.591Z",
            "created_at": "2016-09-21T16:19:12.591Z",
            "deleted_at": null,
            "definition": "Nursing homes are inpatient health care facilities that provide nursing and custodial care over an extended period of time for individuals who need 24-hour care and supervision according to applicable laws and regulations. Related Terms: Long Term Care "
        },
        {
            "id": 202,
            "code": "INS",
            "description": "Institutional Abuse/Neglect",
            "service_category_id": 11,
            "updated_at": "2016-09-21T16:19:12.516Z",
            "created_at": "2016-09-21T16:19:12.516Z",
            "deleted_at": null,
            "definition": "Programs that investigate and intervene because of suspected physical, emotional or financial abuse, neglect or exploitation of residents living in long term care facilities including nursing homes, personal care homes, assisted living communities and community living arrangements. Programs may also investigate problems or concerns of the rights of residents in these facilities. Related terms. LTCO, Ombudsman, HFR, DCH, Healthcare Facility Regulation Division, licensure, \r\n\r\n \r\n\r\n"
        },
        {
            "id": 141,
            "code": "GA",
            "description": "Geriatric Assessment",
            "service_category_id": 19,
            "updated_at": "2016-09-21T16:19:12.398Z",
            "created_at": "2016-09-21T16:19:12.398Z",
            "deleted_at": null,
            "definition": "Geriatric Assessment Programs evaluate the medical and/or psychosocial functioning of an elderly person, arrives at a diagnosis and identifies possible treatment. Related Terms: Assessment, Evaluation, Care Management"
        },
        {
            "id": 166,
            "code": "HC",
            "description": "Health Centers",
            "service_category_id": 23,
            "updated_at": "2016-09-21T16:19:12.444Z",
            "created_at": "2016-09-21T16:19:12.444Z",
            "deleted_at": null,
            "definition": "Health Centers are operated outpatient facilities that primarily provide public health services including health screening, immunizations, family planning, prenatal and well child care as well as diagnosis and treatment of very minor conditions. Services are available on an ability to pay basis and target low-income and indigent residents of the community. Related Terms: shots, vaccinations, injections, county health department\r\n\r\n"
        },
        {
            "id": 146,
            "code": "GN",
            "description": "General Hospitals",
            "service_category_id": 25,
            "updated_at": "2016-09-21T16:19:12.408Z",
            "created_at": "2016-09-21T16:19:12.408Z",
            "deleted_at": null,
            "definition": "General Hospitals are licensed to operate twenty-four hours per day and offer diagnostic and treatment services for people who have any of a variety of illnesses or injuries which require immediate, short-term intervention or that require them to occupy a bed for at least one night. Some hospitals may also provide emergency and critical care."
        },
        {
            "id": 152,
            "code": "GRH",
            "description": "Geriatric Hospitals",
            "service_category_id": 25,
            "updated_at": "2016-09-21T16:19:12.419Z",
            "created_at": "2016-09-21T16:19:12.419Z",
            "deleted_at": null,
            "definition": "Geriatric Hospitals are facilities staffed by geriatric specialists who provide comprehensive, diagnostic and treatment services for elderly individuals who have diseases or conditions that may be associated with advanced age. Related Terms: older adult, seniors\r\n"
        },
        {
            "id": 377,
            "code": "XHMC",
            "description": "Hospital Based Memory Clinics",
            "service_category_id": 25,
            "updated_at": "2016-09-21T16:19:12.852Z",
            "created_at": "2016-09-21T16:19:12.852Z",
            "deleted_at": null,
            "definition": "Hospital Based clinics that provide memory based diagnositc and treatment services in a outpatient setting"
        },
        {
            "id": 180,
            "code": "HHA",
            "description": "Home Health Agencies",
            "service_category_id": 27,
            "updated_at": "2016-09-21T16:19:12.471Z",
            "created_at": "2016-09-21T16:19:12.471Z",
            "deleted_at": null,
            "definition": "Home Health Agencies are programs that provide short-term skilled nursing and/or rehabilitative services to homebound persons. Medicare, Medicaid, Veteran's Administration or private insurance may cover these services. Medicare will pay 100% for services when the doctor orders skilled, intermittent nursing care or rehabilitation services. Medicare will also pay 100% when the doctor orders the services of a part-time home health aide in conjunction with those skilled services. The home health aide will assist with bathing and dressing of the patient, and light housekeeping in the area the patient occupies. These personnel remain in the patient's home only long enough to perform their specific tasks. The Georgia Department of Human Resources licenses home health agencies. See also Home-Based Services, Home Care Providers, Home Health Care."
        }, {
            "id": 195,
            "code": "HSPC",
            "description": "Hospice Care",
            "service_category_id": 22,
            "updated_at": "2016-09-21T16:19:12.503Z",
            "created_at": "2016-09-21T16:19:12.503Z",
            "deleted_at": null,
            "definition": "Hospice Care - Hospice Care are programs that provide a full range of palliative and supportive services for terminally ill people who are in the final stages of their illnesses and for their families. Services may include medical care, pain and symptom management, home nurse visitation, care management, counseling and bereavement services for the patient and members of the family. A hospice may be a freestanding facility, a unit in a hospital or other institution, a program of a hospital, agency or institution, or service delivered in the home or residential institution. Related Terms: palliative care, pain management, end of life care\r\n\r\n"
        },
        {
            "id": 56,
            "code": "CO",
            "description": "Insurance Counseling",
            "service_category_id": 32,
            "updated_at": "2016-09-21T16:19:12.228Z",
            "created_at": "2016-09-21T16:19:12.228Z",
            "deleted_at": null,
            "definition": "Insurance Counseling are programs that provide health insurance information, counseling and assistance for older adults and their families when they need help understanding Medicare, Medicaid, medical bills, claims, correspondence, supplemental insurance or long-term care insurance.\r\n"
        },
        {
            "id": 280,
            "code": "PR",
            "description": "Parks & Recreation",
            "service_category_id": 35,
            "updated_at": "2016-09-21T16:19:12.665Z",
            "created_at": "2016-09-21T16:19:12.665Z",
            "deleted_at": null,
            "definition": "Parks and Recreation Programs provide facilities and a variety of organized recreational activities for people of all ages.\r\n\r\n\r\n"
        },
        {
            "id": 311,
            "code": "SCT",
            "description": "Senior Centers",
            "service_category_id": 35,
            "updated_at": "2016-09-21T16:19:12.723Z",
            "created_at": "2016-09-21T16:19:12.723Z",
            "deleted_at": null,
            "definition": "Senior Centers serve as focal points for older adults in the community and which may offer, at a single location, a wide variety of activities and services including arts and crafts, congregate meals, transportation, health screenings, educational services and recreational activities.\r\n\r\n"
        },
        {
            "id": 225,
            "code": "MHH",
            "description": "Mental Health Hotlines",
            "service_category_id": 37,
            "updated_at": "2016-09-21T16:19:12.559Z",
            "created_at": "2016-09-21T16:19:12.559Z",
            "deleted_at": null,
            "definition": "Mental Health Hotlines are programs that provide telephone crisis intervention services for individuals whose emotional distress may range in severity from difficulty in coping with daily pressures to the crisis of a psychotic episode. The objective is to diffuse the immediate crisis and to help the person develop a plan for establishing linkage with resources for ongoing assistance, if required. Related terms: behavioral health, substance abuse, addictive disease, Psychiatric, suicide, suicidal"
        },
        {
            "id": 223,
            "code": "MHC",
            "description": "Mental Health Counseling/Therapy",
            "service_category_id": 37,
            "updated_at": "2016-09-21T16:19:12.555Z",
            "created_at": "2016-09-21T16:19:12.555Z",
            "deleted_at": null,
            "definition": "Mental Health Counseling are programs that specialize in the treatment of individuals who have identified mental or emotional disorders with the objective of helping them to eliminate or reduce the severity of their symptoms, to mediate disturbed patterns or behavior, to promote positive personality growth and development and to maximize the individual's ability to function as independently as possible. Treatment may utilize therapeutic techniques derived from one or more theoretical counseling approaches. Related terms: depression, behavioral health, substance abuse, addictive disease, Psychiatric \r\n"
        },
        {
            "id": 52,
            "code": "CM",
            "description": "Congregate Meals",
            "service_category_id": 40,
            "updated_at": "2016-09-21T16:19:12.220Z",
            "created_at": "2016-09-21T16:19:12.220Z",
            "deleted_at": null,
            "definition": "Congregate Meals - Congregate Meals are programs that provide nutritious meals for senior citizens and other eligible persons who may be a risk for nutritional deficits without assistance and who can benefit from an opportunity to socialize with their peers. Congregate meals are usually served in senior centers. Related Term: Group Lunch\r\n"
        },
        {
            "id": 117,
            "code": "EF",
            "description": "Emergency Food",
            "service_category_id": 40,
            "updated_at": "2016-09-21T16:19:12.352Z",
            "created_at": "2016-09-21T16:19:12.352Z",
            "deleted_at": null,
            "definition": "Emergency Food - Emergency Food are programs that provide a limited amount of food for individuals or families during times of personal crisis or for people who have no food or cannot afford to purchase food at retail costs. Related Terms: Surplus Commodities Program, Food Pantry, Soup Kitchen\r\n"
        },
        {
            "id": 176,
            "code": "HDM",
            "description": "Home Delivered Meals",
            "service_category_id": 40,
            "updated_at": "2016-09-21T16:19:12.464Z",
            "created_at": "2016-09-21T16:19:12.464Z",
            "deleted_at": null,
            "definition": "Home-Delivered Meals are programs that prepare and/or deliver meals five or more days per week to homebound elderly or disabled adults. Modified meals, designed to meet special dietary needs, are available in the home-delivered meal programs with a physician's prescription. Related Term: Meals on Wheels\r\n"
        },
        {
            "id": 381,
            "code": "XSA",
            "description": "Substance Abuse Services",
            "service_category_id": 53,
            "updated_at": "2016-09-21T16:19:12.860Z",
            "created_at": "2016-09-21T16:19:12.860Z",
            "deleted_at": null,
            "definition": "Substance Abuse Services are programs that provide preventive, diagnostic and inpatient, outpatient and residential treatment services as well as transitional support for people who have physical and/or psychological dependency on one or a combination of addictive substances including tobacco, alcohol, and/or other drugs; or whose use of these substances has impaired their physical or mental health or their personal, social, or occupational functioning. Related terms: behavioral health, substance abuse, addictive disease, Psychiatric\r\n\r\n\r\n\r\n"
        },
        {
            "id": 388,
            "code": "XVAS",
            "description": "Veterans Benefits",
            "service_category_id": 54,
            "updated_at": "2016-09-21T16:19:12.873Z",
            "created_at": "2016-09-21T16:19:12.873Z",
            "deleted_at": null,
            "definition": "Benefits provided by law to former members of the armed services who were discharged under conditions other than dishonorable, with eligibility based on length or type of service or degree of disability."
        },
        {
            "id": 384,
            "code": "XVAH",
            "description": "Veterans Hospitals/Clinics",
            "service_category_id": 54,
            "updated_at": "2016-09-21T16:19:12.866Z",
            "created_at": "2016-09-21T16:19:12.866Z",
            "deleted_at": null,
            "definition": "Healthcare hospitals and clinics operated by the United States Department of Veterans Affairs providing inpatient and outpatient medical, surgical, primary care, behavioral health services and specialized care for eligible veterans discharged under conditions other than dishonorable.\r\n"
        },
        {
            "id": 369,
            "code": "WPCD",
            "description": "Chronic Disease Self Management",
            "service_category_id": 52,
            "updated_at": "2016-09-21T16:19:12.836Z",
            "created_at": "2016-09-21T16:19:12.836Z",
            "deleted_at": null,
            "definition": "The Chronic Disease Self Management Program is a program offered by Georgia's Area Agencies on Aging and is composed of workshops for people with chronic health problems, presented at convenient locations throughout the community. Trained leaders, who may have a chronic disease themselves, facilitate the workshops. \r\nTopics may include: \r\nTechniques to deal with problems such as frustration, fatigue, pain and isolation \r\nAppropriate exercise for maintaining and improving strength, flexibility and endurance \r\nAppropriate use of medications \r\nCommunicating effectively with family, friends and health professionals \r\nNutrition \r\nHow to evaluate new treatments \r\n"
        },
        {
            "id": 364,
            "code": "WMMP",
            "description": "Medication Management Programs",
            "service_category_id": 52,
            "updated_at": "2016-09-21T16:19:12.826Z",
            "created_at": "2016-09-21T16:19:12.826Z",
            "deleted_at": null,
            "definition": "Medication Management Programs are programs that provide educational interventions to teach the importance of maintaining a medication regimen, techniques to manage side effects and drug interactions, and the use of strategies and devices such as pill boxes to organize timing for the self-administration of prescribed medications in order for one to improve and sustain adherence to their medication regimen. Related terms: prescription, pills"
        },
        {
            "id": 373,
            "code": "WPSE",
            "description": "Senior Exercise Programs",
            "service_category_id": 52,
            "updated_at": "2016-09-21T16:19:12.844Z",
            "created_at": "2016-09-21T16:19:12.844Z",
            "deleted_at": null,
            "definition": "Senior Exercise Programs offer an exercise program specifically for seniors. Exercises are developed and/or adapted for people who are aging, may include walking, stretching and other similar activities. Related terms: exercize, dance yoga "
        },
        {
            "id": 371,
            "code": "WPFP",
            "description": "Fall Prevention Programs",
            "service_category_id": 52,
            "updated_at": "2016-09-21T16:19:12.839Z",
            "created_at": "2016-09-21T16:19:12.839Z",
            "deleted_at": null,
            "definition": "Fall Prevention programs educate and increase public awareness of the risk factors associated with trips, slips and falls by older adults in and around their homes and communities and the steps that can be taken prevent their falls. These programs may address physical conditions as well as environmental hazards that increase the opportunity for a fall to occur. Related term: Trip, safety, "
        },
        {
            "id": 172,
            "code": "HDM",
            "description": "Home Care Provider/ Personal Care",
            "service_category_id": 172,
            "updated_at": "2016-09-21T16:19:12.464Z",
            "created_at": "2016-09-21T16:19:12.464Z",
            "deleted_at": null,
            "definition": "Home Care Provider/ Personal Care"
        },
    ],
    Community: [
        {
            "id": 5,
            "code": "ADC",
            "description": "Adult Day Care",
            "service_category_id": 3,
            "updated_at": "2016-09-21T16:19:12.122Z",
            "created_at": "2016-09-21T16:19:12.122Z",
            "deleted_at": null,
            "definition": "Adult Day Care Programs are community-based programs designed to meet the needs of adults with functional impairments. Through a structured and comprehensive program a variety of health, social and related support services are offered in a supervised protective setting during some portion of a twenty-four hour day. The Georgia Department of Human Services licenses adult day care programs. Related terms: Adult Day Health, Medicaid Waiver Programs, Respite Care, Out of Home Respite"
        },
        {
            "id": 2,
            "code": "AAA",
            "description": "Area Agencies on Aging",
            "service_category_id": 41,
            "updated_at": "2016-09-21T16:19:12.114Z",
            "created_at": "2016-09-21T16:19:12.114Z",
            "deleted_at": null,
            "definition": "Area Agencies on Aging are public or private nonprofit agencies designated by the state to address the needs and concerns of all older Americans at the local level. Reltated term: AAA, Aging Services, Title III"
        },
        {
            "id": 126,
            "code": "EP",
            "description": "Estate Planning",
            "service_category_id": 18,
            "updated_at": "2016-09-21T16:19:12.369Z",
            "created_at": "2016-09-21T16:19:12.369Z",
            "deleted_at": null,
            "definition": "Estate Planning Assistance Programs make arrangements for an individual's property and estate, taking into account the laws covering wills, taxes, insurance, property and trusts, so as to gain maximum benefit of all laws while carrying out the person's own wishes for the disposition of the property upon his or her death. Related Terms: Legal Services, Accounting Services\r\n\r\n"
        },
        {
            "id": 280,
            "code": "PR",
            "description": "Parks & Recreation",
            "service_category_id": 35,
            "updated_at": "2016-09-21T16:19:12.665Z",
            "created_at": "2016-09-21T16:19:12.665Z",
            "deleted_at": null,
            "definition": "Parks and Recreation Programs provide facilities and a variety of organized recreational activities for people of all ages.\r\n\r\n\r\n"
        },
        {
            "id": 311,
            "code": "SCT",
            "description": "Senior Centers",
            "service_category_id": 35,
            "updated_at": "2016-09-21T16:19:12.723Z",
            "created_at": "2016-09-21T16:19:12.723Z",
            "deleted_at": null,
            "definition": "Senior Centers serve as focal points for older adults in the community and which may offer, at a single location, a wide variety of activities and services including arts and crafts, congregate meals, transportation, health screenings, educational services and recreational activities.\r\n\r\n"
        },
        {
            "id": 308,
            "code": "SCL",
            "description": "Senior Clubs",
            "service_category_id": 35,
            "updated_at": "2016-09-21T16:19:12.717Z",
            "created_at": "2016-09-21T16:19:12.717Z",
            "deleted_at": null,
            "definition": "Senior Clubs are programs that provide organized opportunities for active senior adults to come together with their peers through participating in picnics, parties, traveling, and a mix of cultural, educational, social and other companionable activities. The primary purpose in gathering is to have fun, stay active, and pass the time in an enjoyable manner.\r\n\t"
        },
        {
            "id": 52,
            "code": "CM",
            "description": "Congregate Meals",
            "service_category_id": 40,
            "updated_at": "2016-09-21T16:19:12.220Z",
            "created_at": "2016-09-21T16:19:12.220Z",
            "deleted_at": null,
            "definition": "Congregate Meals - Congregate Meals are programs that provide nutritious meals for senior citizens and other eligible persons who may be a risk for nutritional deficits without assistance and who can benefit from an opportunity to socialize with their peers. Congregate meals are usually served in senior centers. Related Term: Group Lunch\r\n"
        },
        {
            "id": 32,
            "code": "BFD",
            "description": "Bus Fares/Discounts",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.176Z",
            "created_at": "2016-09-21T16:19:12.176Z",
            "deleted_at": null,
            "definition": "Bus Fare discount programs that provide cash or tokens for individuals who cannot afford to pay for the fare. Also included are programs that issue bus passes for older adults and people who have disabilities that enable them to utilize accessible mass transit services at discount rates."
        },
        {
            "id": 111,
            "code": "DSP",
            "description": "Driver Safety/Evaluation Programs",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.339Z",
            "created_at": "2016-09-21T16:19:12.339Z",
            "deleted_at": null,
            "definition": "Programs that offer courses for individuals age fifty-five and older which focus on safe driving practices for older adults. People who successfully complete the course receive certificates that make them eligible for reduced motor vehicle liability insurance."
        },
        {
            "id": 241,
            "code": "NET",
            "description": "Non-Emergency Transportation",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.589Z",
            "created_at": "2016-09-21T16:19:12.589Z",
            "deleted_at": null,
            "definition": "Non-Emergency Transportation programs provide non-emergency transportation for individuals who require medical services and who, because of limited funds or physical condition, are unable to use other available means of transportation."
        },
        {
            "id": 334,
            "code": "TAD",
            "description": "Public Paratransit",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.768Z",
            "created_at": "2016-09-21T16:19:12.768Z",
            "deleted_at": null,
            "definition": "Section 223 of the Americans with Disabilities Act of 1990 (ADA) requires that public entities which operate non-commuter fixed route transportation services also provide complementary paratransit service for individuals unable to use the fixed route system. The regulations issued by the U.S. Department of Transportation, which implement this portion of the law, specify to whom and under what circumstances this service is to be provided. In addition, the regulations require public entities which are subject to the complementary paratransit requirements to develop and administer a process for determining if individuals who request service meet the regulatory criteria for eligibility"
        },
        {
            "id": 285,
            "code": "PUB",
            "description": "Public Transportation",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.674Z",
            "created_at": "2016-09-21T16:19:12.674Z",
            "deleted_at": null,
            "definition": "Public Transportation Authorities that provide fixed-route mass transit services that utilize buses and/or metro rail services to transport people within the limits of city, a metropolitan area, a county or a multi-county region or to move people from outlying areas into a city or metropolitan area. Related Term: Bus, ride, \r\n"
        },

        {
            "id": 373,
            "code": "WPSE",
            "description": "Senior Exercise Programs",
            "service_category_id": 52,
            "updated_at": "2016-09-21T16:19:12.844Z",
            "created_at": "2016-09-21T16:19:12.844Z",
            "deleted_at": null,
            "definition": "Senior Exercise Programs offer an exercise program specifically for seniors. Exercises are developed and/or adapted for people who are aging, may include walking, stretching and other similar activities. Related terms: exercize, dance yoga "
        },
        {
            "id": 371,
            "code": "WPFP",
            "description": "Fall Prevention Programs",
            "service_category_id": 52,
            "updated_at": "2016-09-21T16:19:12.839Z",
            "created_at": "2016-09-21T16:19:12.839Z",
            "deleted_at": null,
            "definition": "Fall Prevention programs educate and increase public awareness of the risk factors associated with trips, slips and falls by older adults in and around their homes and communities and the steps that can be taken prevent their falls. These programs may address physical conditions as well as environmental hazards that increase the opportunity for a fall to occur. Related term: Trip, safety, "
        },
    ],
    assistanceandBenefits: [
        {
            "id": 19,
            "code": "APII",
            "description": "Adult Protective Srvs - Intake",
            "service_category_id": 11,
            "updated_at": "2016-09-21T16:19:12.151Z",
            "created_at": "2016-09-21T16:19:12.151Z",
            "deleted_at": null,
            "definition": "Adult Protective Services Intake programs accept reports and investigate claims of physical or emotional abuse, unsafe or hazardous living conditions, exploitation or abandonment. Related terms: Elder Abuse, Neglect, Financial Exploitation "
        },
        {
            "id": 2,
            "code": "AAA",
            "description": "Area Agencies on Aging",
            "service_category_id": 41,
            "updated_at": "2016-09-21T16:19:12.114Z",
            "created_at": "2016-09-21T16:19:12.114Z",
            "deleted_at": null,
            "definition": "Area Agencies on Aging are public or private nonprofit agencies designated by the state to address the needs and concerns of all older Americans at the local level. Reltated term: AAA, Aging Services, Title III"
        },
        {
            "id": 202,
            "code": "INS",
            "description": "Institutional Abuse/Neglect",
            "service_category_id": 11,
            "updated_at": "2016-09-21T16:19:12.516Z",
            "created_at": "2016-09-21T16:19:12.516Z",
            "deleted_at": null,
            "definition": "Programs that investigate and intervene because of suspected physical, emotional or financial abuse, neglect or exploitation of residents living in long term care facilities including nursing homes, personal care homes, assisted living communities and community living arrangements. Programs may also investigate problems or concerns of the rights of residents in these facilities. Related terms. LTCO, Ombudsman, HFR, DCH, Healthcare Facility Regulation Division, licensure, \r\n\r\n \r\n\r\n"
        },
        {
            "id": 118,
            "code": "EFA",
            "description": "Emergency Financial Assistance",
            "service_category_id": 17,
            "updated_at": "2016-09-21T16:19:12.354Z",
            "created_at": "2016-09-21T16:19:12.354Z",
            "deleted_at": null,
            "definition": "Emergency Financial Assistance Programs make mortgage or rent payments, or that pay all or a portion of the utility bills for people who cannot afford to make the payments themselves, who are at risk of losing their homes, or at risk of eviction without assistance or whose utilities have been, or are at risk of being, shut off and who qualify for this service on the basis of income or need. Programs may also assist with other needs as determined by the individual program. See also Housing Counseling. Related Terms: Rental Assistance Payments, Emergency Financial Assistance, Food Pantry, Clothes Closet, Patient Assistance Programs, Health Clinics\r\n"
        },
        {
            "id": 355,
            "code": "UD",
            "description": "Utility Discount Programs",
            "service_category_id": 17,
            "updated_at": "2016-09-21T16:19:12.809Z",
            "created_at": "2016-09-21T16:19:12.809Z",
            "deleted_at": null,
            "definition": "Utility Discounts - Utility Discounts Programs offer special services which support individuals' ability to make their utility payments. Programs may require that people be in a certain income bracket, have limited resources and/or be a certain age. Related Terms: Senior Discounts, LIHEAP "
        },
        {
            "id": 330,
            "code": "SSRB",
            "description": "Social Security Retirement Benefits",
            "service_category_id": 34,
            "updated_at": "2016-09-21T16:19:12.761Z",
            "created_at": "2016-09-21T16:19:12.761Z",
            "deleted_at": null,
            "definition": "Social Security Retirement Benefits is a federal program that pays retirement, disability, and survivor's benefits to eligible workers and/or their eligible family members. The Social Security Administration administers this program. Related terms SS, Old Age Pensions\r\n\r\n"
        },
        {
            "id": 329,
            "code": "SSI",
            "description": "Supplemental Security Income",
            "service_category_id": 34,
            "updated_at": "2016-09-21T16:19:12.758Z",
            "created_at": "2016-09-21T16:19:12.758Z",
            "deleted_at": null,
            "definition": "Supplemental Security Income is a federal income-maintenance program that provides basic financial assistance in the form of monthly checks for people who are age sixty-five and older, or blind, or disabled and who have little or no income and assets. The Social Security Administration administers this program.\r\n\r\n\r\n"
        },
        {
            "id": 56,
            "code": "CO",
            "description": "Insurance Counseling",
            "service_category_id": 32,
            "updated_at": "2016-09-21T16:19:12.228Z",
            "created_at": "2016-09-21T16:19:12.228Z",
            "deleted_at": null,
            "definition": "Insurance Counseling are programs that provide health insurance information, counseling and assistance for older adults and their families when they need help understanding Medicare, Medicaid, medical bills, claims, correspondence, supplemental insurance or long-term care insurance.\r\n"
        },
        {
            "id": 123,
            "code": "ELA",
            "description": "Elder Law Attorneys",
            "service_category_id": 36,
            "updated_at": "2016-09-21T16:19:12.364Z",
            "created_at": "2016-09-21T16:19:12.364Z",
            "deleted_at": null,
            "definition": "Elder Law Attorneys are programs that are staffed by attorneys who specialize in elder law and who offer advice and guidance to senior citizens about legal matters. Related Terms: wills, power of attorney, estate planning, guardianship, living wills, advanced directives\r\n"
        },
        {
            "id": 257,
            "code": "PC",
            "description": "Probate Courts",
            "service_category_id": 36,
            "updated_at": "2016-09-21T16:19:12.620Z",
            "created_at": "2016-09-21T16:19:12.620Z",
            "deleted_at": null,
            "definition": "Probate Courts are courts within the civil division of the county Superior Court system that handle guardianships, processing decedents' wills, and the settlement of estates. Related terms guardianship\r\n"
        },
        {
            "id": 117,
            "code": "EF",
            "description": "Emergency Food",
            "service_category_id": 40,
            "updated_at": "2016-09-21T16:19:12.352Z",
            "created_at": "2016-09-21T16:19:12.352Z",
            "deleted_at": null,
            "definition": "Emergency Food - Emergency Food are programs that provide a limited amount of food for individuals or families during times of personal crisis or for people who have no food or cannot afford to purchase food at retail costs. Related Terms: Surplus Commodities Program, Food Pantry, Soup Kitchen\r\n"
        },
        {
            "id": 388,
            "code": "XVAS",
            "description": "Veterans Benefits",
            "service_category_id": 54,
            "updated_at": "2016-09-21T16:19:12.873Z",
            "created_at": "2016-09-21T16:19:12.873Z",
            "deleted_at": null,
            "definition": "Benefits provided by law to former members of the armed services who were discharged under conditions other than dishonorable, with eligibility based on length or type of service or degree of disability."
        },
        {
            "id": 382,
            "code": "XVA",
            "description": "Veterans Information and Assistance",
            "service_category_id": 54,
            "updated_at": "2016-09-21T16:19:12.862Z",
            "created_at": "2016-09-21T16:19:12.862Z",
            "deleted_at": null,
            "definition": "Programs offered by the United States Department of Veterans Affairs or under contract to the agency, affiliated non-profit organizations or other state or local governmental entities that provide information and linking to resources on a variety of veterans specific services.\r\n"
        },
    ],
    Caregiver: [
        {
            "id": 2,
            "code": "AAA",
            "description": "Area Agencies on Aging",
            "service_category_id": 41,
            "updated_at": "2016-09-21T16:19:12.114Z",
            "created_at": "2016-09-21T16:19:12.114Z",
            "deleted_at": null,
            "definition": "Area Agencies on Aging are public or private nonprofit agencies designated by the state to address the needs and concerns of all older Americans at the local level. Reltated term: AAA, Aging Services, Title III"
        },
        {
            "id": 48,
            "code": "CEC",
            "description": "Caregiver Education Classes",
            "service_category_id": 7,
            "updated_at": "2016-09-21T16:19:12.212Z",
            "created_at": "2016-09-21T16:19:12.212Z",
            "deleted_at": null,
            "definition": "Caregiver Education Programs are programs that provide information and training, usually in a group setting, for family, friends and professionals who are providing support and care for an individual, who due to age, disability or illness is unable to provide their own care. Subject areas may include basic psychology, nursing, special education, sociology, understanding dementia, emotional issues, legal/financial concerns, community resources, end-of-life issues, communication techniques, reducing agitation, and the role of medication as well as training for “hands-on care”. Related terms: Support Groups, Community Helplines, Workshops"
        },
        {
            "id": 67,
            "code": "csp",
            "description": "Caregiver Support Programs",
            "service_category_id": 7,
            "updated_at": "2016-09-21T16:19:12.252Z",
            "created_at": "2016-09-21T16:19:12.252Z",
            "deleted_at": null,
            "definition": "Caregiver Support Programs are programs that offer support to family, friends and significant others caring for an individual, who due to age, disability or illness is unable to provide their own care. Support may be in the form of respite from caregiving duties, financial assistance to cover the expenses of caregiving or emotional support to enable the caregiver to cope with their responsibilities. Related terms: Support Groups, Voucher Programs, Caregiver Education Classes, In Home Respite, Out of Home Respite\r\n\t\r\n"
        },
    ],
    transportation: [
        {
            "id": 32,
            "code": "BFD",
            "description": "Bus Fares/Discounts",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.176Z",
            "created_at": "2016-09-21T16:19:12.176Z",
            "deleted_at": null,
            "definition": "Bus Fare discount programs that provide cash or tokens for individuals who cannot afford to pay for the fare. Also included are programs that issue bus passes for older adults and people who have disabilities that enable them to utilize accessible mass transit services at discount rates."
        },
        {
            "id": 111,
            "code": "DSP",
            "description": "Driver Safety/Evaluation Programs",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.339Z",
            "created_at": "2016-09-21T16:19:12.339Z",
            "deleted_at": null,
            "definition": "Programs that offer courses for individuals age fifty-five and older which focus on safe driving practices for older adults. People who successfully complete the course receive certificates that make them eligible for reduced motor vehicle liability insurance."
        },
        {
            "id": 241,
            "code": "NET",
            "description": "Non-Emergency Transportation",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.589Z",
            "created_at": "2016-09-21T16:19:12.589Z",
            "deleted_at": null,
            "definition": "Non-Emergency Transportation programs provide non-emergency transportation for individuals who require medical services and who, because of limited funds or physical condition, are unable to use other available means of transportation."
        },
        {
            "id": 334,
            "code": "TAD",
            "description": "Public Paratransit",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.768Z",
            "created_at": "2016-09-21T16:19:12.768Z",
            "deleted_at": null,
            "definition": "Section 223 of the Americans with Disabilities Act of 1990 (ADA) requires that public entities which operate non-commuter fixed route transportation services also provide complementary paratransit service for individuals unable to use the fixed route system. The regulations issued by the U.S. Department of Transportation, which implement this portion of the law, specify to whom and under what circumstances this service is to be provided. In addition, the regulations require public entities which are subject to the complementary paratransit requirements to develop and administer a process for determining if individuals who request service meet the regulatory criteria for eligibility"
        },
        {
            "id": 285,
            "code": "PUB",
            "description": "Public Transportation",
            "service_category_id": 48,
            "updated_at": "2016-09-21T16:19:12.674Z",
            "created_at": "2016-09-21T16:19:12.674Z",
            "deleted_at": null,
            "definition": "Public Transportation Authorities that provide fixed-route mass transit services that utilize buses and/or metro rail services to transport people within the limits of city, a metropolitan area, a county or a multi-county region or to move people from outlying areas into a city or metropolitan area. Related Term: Bus, ride, \r\n"
        },
    ]

}

export function generateOptionsForServicesAndCategories() {
    console.log('call')
    return (dispatch) => {
        // make call to get all services
        // search_module.getCategories()

        dispatch(updateOptionsForCategories(category));
        dispatch(serviceCategeoryForGlossary(service));

        // .then(response => {
        //     dispatch(updateOptionsForCategories(response));
        // })
        // .catch(response => {
        //     console.log('There is some error!', JSON.stringify(response, null, 4));
        // });

        search_module.getCounties()
            .then(response => {
                dispatch(setCountyOptions(response))
            })
            .catch(response => {
                // console.log('There is some error!', JSON.stringify(response, null, 4));
                //return signoutUser(dispatch);
            });

        // search_module.getServiceCodes()
        //     .then(response => {
        //         dispatch(updateOptionsForServiceCodes(response));
        //     })
        //     .catch(response => {
        //         // console.log('There is some error!', JSON.stringify(response, null, 4));
        //         return signoutUser(dispatch);
        //     });


    }


}

export function setCountyOptions(options) {
    let _options = _.sortBy(options, 'label')
    // console.log(options, type.FETCH_COUNTY_OPTIONS)
    return {
        type: type.FETCH_COUNTY_OPTIONS,
        payload: _options
    }
}

export function setCountyOfLocation(county, query) {
    let next_query = _.clone(query)
    console.log('>>> what is county?', next_query,);
    const counties = [];
    if (county && Array.isArray(county) && county.length > 0) {
        county.forEach((c) => counties.push(c.name));
    }
    next_query.county = county.name;
    console.log('>>> setting the county query:', next_query.county);

    return {
        type: type.SET_COUNTY_OF_LOCATION,
        payload: county,
        query: next_query
    }
}

export function setPrice(price, query) {
    let next_query = _.clone(query)
    console.log('>>> what is price?', price);
    //const cost = [];
    // if (price && Array.isArray(price) && price.length > 0) {
    //     price.forEach((c) => cost.push(c.value));
    // }
    next_query.price = price.value;
    console.log('>>> setting the price query:', next_query.price);

    return {
        type: type.SET_PRICE,
        payload: price,
        query: next_query
    }
}

export function updateOptionsForCategories(category_options) {
    category_options = _.sortBy(category_options, 'label');
    // console.log("url cleaned_data : ", category_options);
    return {
        type: type.FILL_CATEGORIES,
        category_options: category_options
    }
}

export function serviceCategeoryForGlossary(allServiceDefination) {
    return {
        type: type.SET_GLOSSARY_SERVICE,
        serviceDefenation: allServiceDefination
    }
}


/**
* Generic handler for select boxes
* @param select Selected values from the control
* @param field The name of the field to handle the selection
* @param query the current query object persisted to state
*/
export function handleSelectChange(select, field, query) {
    return dispatch => {
        let next_query = _.clone(query);

        if (field === 'category') {
            const category_id = select.id
            console.log('category_id', category_id)
            let cleaned_data = [];//Health

            if (category_id === 1) {
                cleaned_data = service.home.map(function (service_code) {
                    return {
                        label: service_code.description,
                        value: service_code.id,
                        definition: service_code.definition,
                        //category_id: category_id
                    }
                })
            } else if (category_id === 2) {
                cleaned_data = service.Health.map(function (service_code) {
                    return {
                        label: service_code.description,
                        value: service_code.id,
                        definition: service_code.definition,
                        //category_id: category_id
                    }
                })

            } else if (category_id === 3) {
                cleaned_data = service.Community.map(function (service_code) {
                    return {
                        label: service_code.description,
                        value: service_code.id,
                        definition: service_code.definition,
                        //category_id: category_id
                    }
                })
            } else if (category_id === 4) {
                cleaned_data = service.assistanceandBenefits.map(function (service_code) {
                    return {
                        label: service_code.description,
                        value: service_code.id,
                        definition: service_code.definition,
                        //category_id: category_id
                    }
                })
            } else if (category_id === 5) {
                cleaned_data = service.Caregiver.map(function (service_code) {
                    return {
                        label: service_code.description,
                        value: service_code.id,
                        definition: service_code.definition,
                        //category_id: category_id
                    }
                })
            } else if (category_id === 6) {
                cleaned_data = service.transportation.map(function (service_code) {
                    return {
                        label: service_code.description,
                        value: service_code.id,
                        definition: service_code.definition,
                        //category_id: category_id
                    }
                })

            }
            dispatch(updateOptionsForServiceCodes(cleaned_data));
            dispatch({
                type: type.UPDATE_SELECTED_OPTIONS,
                selection: select,
                field: 'category',
                query: next_query
            })
        }

        if (field === 'service') {
            let category_id = '';

            if (select && select.length > 0) {

                let service_array = [];
                let service_code = []
                select.map(function (service) {
                    category_id = service.category_id;
                    console.log('Value in handle select change : ', util.inspect(service));
                    service_array.push(service);
                    service_code.push(service.value);

                })

                next_query.code = service_code;
                dispatch(
                    {
                        type: type.UPDATE_SELECTED_OPTIONS,
                        selection: service_array,
                        field: 'service',
                        query: next_query
                    }
                )

            } else {
                next_query.code = [];
                dispatch(
                    {
                        type: type.UPDATE_SELECTED_OPTIONS,
                        selection: [],
                        field: 'service',
                        query: next_query
                    }
                )
            }
        }
    }

}

export function handleSelectChange1(select, field, query) {
    return dispatch => {
        // The query is going to change, go ahead and create a new one
        let next_query = _.clone(query);
        let category_id = '';

        console.log('next_query', select, field,)

        if (field === 'service') {
            if (select && select.length > 0) {

                let service_array = [];

                select.map(function (service) {
                    category_id = service.category_id;
                    // console.log('Value in handle select change : ', util.inspect(service));
                    service_array.push(service.value);
                })
                console.log("SELECT: ", select, ">>>QUERY: ", query, 'service_array', service_array)

                if (category_id > 0) {
                    search_module.getServiceCodesByCategory(category_id)
                        .then(response => {
                            search_module.getCategoryById(category_id)
                                .then(response => {

                                    let query_array = [response.data.code];
                                    dispatch({
                                        type: type.UPDATE_SELECTED_OPTIONS,
                                        selection: response.data.id,
                                        field: 'category',
                                        query: next_query
                                    })
                                })
                            dispatch(updateOptionsForServiceCodes(response));
                        })
                }

                next_query.code = service_array;
                dispatch(
                    {
                        type: type.UPDATE_SELECTED_OPTIONS,
                        selection: service_array,
                        field: 'service',
                        query: next_query
                    }
                )

            } else {
                next_query.code = [];
                dispatch(
                    {
                        type: type.UPDATE_SELECTED_OPTIONS,
                        selection: [],
                        field: 'service',
                        query: next_query
                    }
                )
            }
        }

        if (field === 'category') {
            // console.log("What is select : ", select);
            if (select) {
                const category_id = select.id;

                search_module.getServiceCodesByCategory(category_id)
                    .then(response => {
                        let query_array = [select.value];
                        next_query.cat = query_array;
                        dispatch(updateOptionsforServiceCategories(response));
                        dispatch({
                            type: type.UPDATE_SELECTED_OPTIONS,
                            selection: select,
                            field: 'category',
                            query: next_query
                        })
                    })
                    .catch(response => {
                        console.log("Error fetching service codes by category : ", util.inspect(response));
                    })
                console.log("What is category_id : ", category_id);
            } else {
                // console.log('We are in the else!!!!!');
                next_query.cat = [];
                dispatch({
                    type: type.UPDATE_SELECTED_OPTIONS,
                    selection: [],
                    field: 'category',
                    query: next_query
                })

                // dispatch(generateOptionsForServicesAndCategories());
                // nothing was selected, just give all
                // dispatch(generateOptionsForServices());
            }
        }
    }
}

export function updateOptionsforServiceCategories(cleaned_data) {
    cleaned_data = _.sortBy(cleaned_data, 'label');
    console.log('FILL_SERVICE_CODES', cleaned_data)

    return {
        type: type.FILL_SERVICE_CODES,
        service_code_options: cleaned_data
    }

}

export function updateOptionsForServiceCodes(service_code_options) {
    service_code_options = _.sortBy(service_code_options, 'label');

    // console.log('service_code_options', service_code_options)

    return {
        type: type.FILL_SERVICE_CODES,
        service_code_options: service_code_options
    }
}



/**
* Using the query object that has been built, fetch results from API
*/
export function fetchResults(q, selected) {
    console.log('What is the incoming q : ', util.inspect(q));
    return dispatch => {
        dispatch(requestResults(q));

        let keys = Object.keys(q);

        console.log('What is the keys array : ', keys);
        let query = "";

        for (var i = 0; i < keys.length; i++) {
            console.log('q[keys[i]]', q[keys[i]])

            if (q[keys[i]] && q[keys[i]].length > 0) {
                let theKey = keys[i].split('_').join(' ');
                console.log('What is the theKey : ', theKey);
                const keyz = [
                    'county',
                    'price',
                    'code',
                    'name'
                ]
                console.log('keyz.indexOf(theKey) > -1', keyz.indexOf(theKey) > -1)
                if (keyz.indexOf(theKey) > -1) {
                    query += (query.length === 0 ? "?" : "&") + theKey + "=" + q[keys[i]];
                }
            }

        }
        const url = `${type.URL_ROOT}${type.URL_SEARCH}${query}`;
        console.log('New search executed. Query for search:', url);
        return axios.get(url)
            .then(response => {
                console.log('response.data', response.data)
                dispatch(receivedResults(response.data, query['offset'], selected));
            })
    }
}

export function fetchShareResults(q) {
    console.log('What is the incoming q : ', util.inspect(q));
    return dispatch => {
        dispatch(requestResults(q));
        const share = true;

        const url = `${type.URL_ROOT}${type.URL_SEARCH}${q}`;
        console.log('New search executed. Query for search:', url);
        return axios.get(url)
            .then(response => {
                console.log('response.data', response.data)
                dispatch(receivedResults(response.data, null, null, share));
            })
    }
}



export function saveShareQuery(q, selected) {
    console.log('What is the incoming q : ', util.inspect(q));
    return dispatch => {
        dispatch(requestResults(q));

        let keys = Object.keys(q);

        console.log('What is the keys array : ', keys);
        let query = "";

        for (var i = 0; i < keys.length; i++) {
            console.log('q[keys[i]]', q[keys[i]])

            if (q[keys[i]] && q[keys[i]].length > 0) {
                let theKey = keys[i].split('_').join(' ');
                console.log('What is the theKey : ', theKey);
                const keyz = [
                    'county',
                    'price',
                    'code',
                    'name'
                ]
                console.log('keyz.indexOf(theKey) > -1', keyz.indexOf(theKey) > -1)
                if (keyz.indexOf(theKey) > -1) {
                    query += (query.length === 0 ? "?" : "&") + theKey + "=" + q[keys[i]];
                }
            }

        }
        const url = `${type.URL_ROOT}${type.URL_SEARCH}${query}`;
        console.log('New search executed. Query for search:', url);
        // return axios.get(url)
        //     .then(response => {
        //         console.log('response.data', response.data)
        //         dispatch(receivedResults(response.data, query['offset'], selected));
        //     })
    }
}


export function resetSearch() {
    return dispatch => {
        dispatch({ type: type.RESTE_SEARCH })

    }
}

/**
* Toggle the progress indicator during search
*/
export function requestResults(query) {
    return {
        type: type.REQUEST_RESULTS,
        isLoading: true,
        query
    }
}

export function receivedResults(json, offset, selected, share) {
    console.log("What is the json in receivedResults : ", util.inspect(json));
    return {
        type: type.FETCH_RESULTS,
        isLoading: false,
        matched_results: json,
        offset: offset,
        selected_page: selected,
        share: share
    }
}

export function setServiceProviderName(value, query) {
    let next_query = _.clone(query);
    next_query.name = encodeURIComponent(value);

    return {
        type: type.SET_SERVICE_PROVIDER_NAME,
        entered_service_provider_name: value,
        query: next_query
    }
}


export function setStartingPoint(value) {
    return {
        type: type.SET_STARTING_POINT,
        value: value
    }
}

export function setEndPointPoint(value) {
    return {
        type: type.SET_END_POINT,
        value: value
    }
}

export function getStartingPointLatLongFromAddress(address) {
    return function (dispatch) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const url = `${type.URL_ROOT}${type.URL_GET_LAT_LNG}?address=${address}`
        console.log('url', url)
        axios.get(url)
            .then(function (response) {
                console.log('response', response.data)
                dispatch({ type: type.STARTING_POINT_LAT_LNG, payload: response.data.startingpointlatlng })
            })
            .catch(function (error) {
                console.log("Error : ", util.inspect(error));
            });
    }
}

export function getMicroDetails(id) {
    return function (dispatch) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const url = `${type.URL_ROOT}${type.URL_GET_MICROSITE_DETAILS}?id=${1}`
        console.log('url', url)
        axios.get(url)
            .then(function (response) {
                console.log('response', response.data)
                // dispatch({ type: type.STARTING_POINT_LAT_LNG, payload: response.data.startingpointlatlng })
            })
            .catch(function (error) {
                console.log("Error : ", util.inspect(error));
            });
    }
}

export function saveSearchKey(query, service, cIP, qurl) {
    return function (dispatch) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const url = `${type.URL_ROOT}${type.SAVE_SEARCH_KEY}`
        console.log('>>> getQuery:', getQuery(query));
        const qry = getQuery(query) ? getQuery(query) : qurl;
        console.log('>>> getQuery qry:', qry);

        let bodyParam = {
            service: JSON.stringify(service),
            countiesServed: query.county,
            serviceProviderName: query.name == undefined ? '' : query.name,
            price: query.price || '',
            cIP: cIP,
            qry: qry

        }
        console.log('>>> bodyParam:', bodyParam);

        console.log('url', url)
        axios.post(url, bodyParam)
            .then(response => {
                console.log('response saveSearchKey', response.data.result)
                dispatch({
                    type: type.SET_SHARE_QUERY,
                    id: response.data.result.id,
                    shareQuery: response.data.result.query
                })
            })
            .catch(error => {
                console.log("Crap we are in the catch!");
                console.log("Error : ", util.inspect(error));
            });
    }
}


export function setStraightLineDistance(distance) {
    return dispatch => {
        dispatch({
            type: type.SET_STRAIGHT_LIINE_DISTANCE,
            distance: distance
        })
    };
}

export function getQuery(q) {
    let keys = Object.keys(q);

    console.log('What is the keys array : ', keys);
    let query = "";

    for (var i = 0; i < keys.length; i++) {
        console.log('q[keys[i]]', q[keys[i]])

        if (q[keys[i]] && q[keys[i]].length > 0) {
            let theKey = keys[i].split('_').join(' ');
            console.log('What is the theKey : ', theKey);
            const keyz = [
                'county',
                'price',
                'code',
                'name'
            ]
            console.log('keyz.indexOf(theKey) > -1', keyz.indexOf(theKey) > -1)
            if (keyz.indexOf(theKey) > -1) {
                query += (query.length === 0 ? "?" : "&") + theKey + "=" + q[keys[i]];
            }
        }

    }
    const url = `${type.URL_ROOT}${type.URL_SEARCH}${query}`;
    console.log('New search executed. Query for search:', url);
    return query
}


export function sendFeedbackForchangesService(comments, service, email) {
    return function (dispatch) {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const url = `${type.URL_ROOT}${type.SEND_FEEDBACK_FOR_CHANGES_SERVICE}`


        let bodyParam = {
            service: JSON.stringify(service),
            comment: comments,
            email: email
        }
        // console.log('>>> bodyParam:', bodyParam);

        console.log('url', url)
        axios.post(url, bodyParam)
            .then(response => {
                console.log('response saveSearchKey', response.data.result)
                dispatch({
                    type: type.SET_SHARE_QUERY,
                    id: response.data.result.id,
                    shareQuery: response.data.result.query
                })
            })
            .catch(error => {
                console.log("Crap we are in the catch!");
                console.log("Error : ", util.inspect(error));
            });
    }
}
