import React from 'react';

export default (props) => {
    const bodyRef = React.createRef();
    const createPdf = () => props.createPdf(bodyRef.current);
    return (
        <section className="pdf-container">
            <section style={{}} className="pdf-toolbar">
                <div className="form-btn mt-4">
                    <button
                        className="btn btn-search"
                        onClick={createPdf}
                    >
                        Create PDF
                    </button>

                </div>
            </section>
            <section className="pdf-body" ref={bodyRef}>
                {props.children}
            </section>
        </section>
    )
}