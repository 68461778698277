import React, { useState, useEffect, useCallback } from 'react';

import Select from 'react-select';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Router, browserHistory } from 'react-router';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import util from 'util'
import _ from 'lodash'
import * as commonActions from './store/actions/commonActions';
import queryString from 'query-string'
import ScaleLoader from "react-spinners/ScaleLoader";

import Search from './containers/search/Search'
import Service from './containers/search/Service'
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function App() {
  const values = queryString.parse(window.location.search)
  const images = useSelector(state => state.common.images);

  console.log('images. images.banner', images.banner)

  const dispatch = useDispatch();
  const [logo, setLogo] = useState(false);
  const [banner, setbanner] = useState(false);
  const [loading, setLoading] = useState(false);


  const loadMicrositeLogoBanner = useCallback(async () => {
    if (values.id) {
      setLoading(true)
      console.log('this.props.match.params', values.id)

      const config = { headers: { 'Content-Type': 'application/json' } };
      // const url = `https://empowerlinepro.com/api/v1/admin/fetch-single-microsite?id=${values.id}`
      const url = `https://api.empowerlinepro.com/v1/fetch-single-microsite?id=${values.id}`
      console.log('url', url)
      await axios.get(url)
        .then(function (response) {
          setLoading(false)
          console.log('response 0', response.data)
          console.log('response 1', response.data.result)

          if (response.data && response.data.result && response.data.result.length > 0) {
            console.log('response 2', response.data)

            setLogo(response.data.result[0].logo)
            setbanner(response.data.result[0].banner)
            dispatch(commonActions.setBannerandLogo(response.data.result[0]));
          }
        })
        .catch(function (error) {
          console.log("Error : ", util.inspect(error));
        });
    }


  }, [dispatch]);

  useEffect(() => {
    loadMicrositeLogoBanner();
  }, [dispatch, loadMicrositeLogoBanner]);
  // useEffect(() => {
  //   if (images.logo) {
  //     setLogo(images.logo)
  //   }
  //   if (images.banner) {
  //     setbanner(images.banner)
  //   }
  // });
  console.log('logo', logo)
  console.log('banner', banner)



  return (
    <div>
      {loading ?
        <div className="sweet-loading" style={{

          position: 'absolute',
          left: '50%',
          marginTop: '20%',
        }}>
          <ScaleLoader
            css={override}
            size={100}
            color={"#eebd60"}
            loading={loading}

          />
        </div>

        : <div> <header>

          {logo ? <div className="container">
            <div className="logo">
              <img src={`https://empowerlinepro.com/assets/microsite/` + logo} />
            </div>
          </div> :
            <div className="container">
              <div className="logo">
                <img src="assets/images/empowerline-logo.png" />
              </div>
            </div>
          }
        </header>

          <section className="banner-sec">
            {banner ? <div className="image">
              <img src={`https://empowerlinepro.com/assets/microsite/` + banner} />
            </div> :
              <div className="image">
                <img src="assets/images/banner.jpg" />
              </div>
            }

            <div className="text transform-middle">
              <h2>search</h2>
            </div>
          </section>



          <Route path="/" exact component={Search} />
          <Route path="/microsite/:name" component={Search} />
          <Route path="/Service" component={Service} />
          <Route path="/Service/:share" component={Service} />

        </div>
      }

    </div>
  );
}

export default App

