import * as type from '../../constant/service/ActionTypes'


export function setBannerandLogo(data) {
    return dispatch => {
        dispatch({
            type: type.SET_LOGO_BANNER,
            payload: data
        })
    };
}