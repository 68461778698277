import React, { useState, useEffect, useCallback } from 'react';
import { Route, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string'
import axios from 'axios';
import util from 'util'
import _ from 'lodash'
import * as searchActions from './Action';
import SearchComponents from '../../components/search/Search';
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/core";
import Doc from '../DocService/DocService';
import HelpMessageBoard from "./HelpMessageBoard"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const Search = (props) => {
    const values = queryString.parse(window.location.search)

    const dispatch = useDispatch();
    const search = useSelector(state => state.search);
    const query = useSelector(state => state.search.query);
    // const selectedCategories = useSelector(state => state.search.selected_categories);
    // state.search.service_code_options,
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [help, setHelp] = useState(false);
    const [glossary, setGlossary] = useState(false);
    const [cIP, setCIP] = useState('');
    const [shareLoading, setShareLoading] = useState(false);

    console.log('values', values)

    const loadShareService = useCallback(async () => {
        if (values.shareid) {
            setShareLoading(true)
            console.log('this.props.match.params', values.id)

            const config = { headers: { 'Content-Type': 'application/json' } };
            // const url = `https://empowerlinepro.com/api/v1/admin/fetch-single-microsite?id=${values.id}`
            // const url = `https://findservices.empowerline.org/get_microsite?id=${values.shareid}`
            const url = `http://localhost:3030/v1/get_share_service?id=${values.shareid}`

            console.log('url', url)
            await axios.get(url)
                .then(function (response) {

                    console.log('response 0', response.data)
                    console.log('response 1', response.data.result)

                    if (response.data && response.data.result && response.data.result.length > 0) {
                        console.log('cIP 2', cIP)
                        dispatch(searchActions.fetchShareResults(response.data.result[0].query))
                        dispatch(searchActions.saveSearchKey(query, [], cIP, response.data.result[0].query))

                        // setLogo(response.data.result[0].logo)
                        // setbanner(response.data.result[0].banner)
                        // dispatch(commonActions.setBannerandLogo(response.data.result[0]));
                    }
                })
                .catch(function (error) {
                    console.log("Error : ", util.inspect(error));
                });
        }


    }, [dispatch]);

    useEffect(() => {
        fetchIP();
        loadShareService();
    }, [dispatch, loadShareService]);


    const loadCategories = useCallback(async () => {

        try {
            dispatch(searchActions.generateOptionsForServicesAndCategories());
        } catch (err) {
            console.log('err', err)
        }
        //(false);
    }, [dispatch]);


    useEffect(() => {
        loadCategories();
    }, [dispatch, loadCategories]);

    useEffect(() => {
        fetchIP()
    }, [])

    const fetchIP = () => {
        fetch("https://api.ipify.org/?format=json")
            .then(response => {
                console.log(response)
                return response.json();
            }, "jsonp")
            .then(res => {
                setCIP(res.ip)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (search.search_results && search.search_results.length > 0) {
            setIsLoading(false);
            setShareLoading(false)
            props.history.push({
                pathname: '/Service'
            });
        } else {
            setIsLoading(false);
            setNotFound(true);

        }
    }, [search]);



    const handleSearch = () => {
        console.log('query', query)
        let service = search.selected_services.map(r => r.label)
        console.log('service', service)
        setIsLoading(true)

        dispatch(searchActions.saveSearchKey(query, service, cIP))
        dispatch(searchActions.fetchResults(query))

    }
    const handleReset = () => {
        console.log('handleReset',)
        dispatch(searchActions.resetSearch());

    }

    const handleServiceProviderTextChange = (field) => {
        dispatch(searchActions.setServiceProviderName(field.target.value, query));
        // this.props.setServiceProviderName(field.target.value, query);
    }

    const handleSelectChange = (field, e, query) => {
        console.log('e, field, this.props.query', e)
        dispatch(searchActions.handleSelectChange(e, field, query));
        //props.handleSelectChange(e, field, this.props.query);
    }

    const handleCountyChange = (e) => {
        console.log('New county: ', e)
        dispatch(searchActions.setCountyOfLocation(e, query));
        //this.props.setCountyOfLocation(e, query);
    }

    const handleSelectPrice = (e) => {
        console.log('New price: ', e)
        dispatch(searchActions.setPrice(e, query));

    }

    const createPdf = (html) => Doc.createPdf(html);

    const renderNotification = () => {
        if (search.meta && search.meta.count == 0) {
            return (
                <div className="alert alert-danger alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <span>Search result not found.</span>
                </div>
            )
        }

    }

    const openHelpModel = () => {
        setGlossary(false)
        setHelp(!help)
    }
    // const refs = React.createRef();

    const handleOpenCloseGlossary = (field, e) => {
        setHelp(false)
        setGlossary(!glossary)
    }

    const renderServiceDef = () => {

        const Servicedef1 = Object.keys(search.serviceDefenation).map(function (service) { return service })
        console.log('search.serviceDefenation', Servicedef1)

        const Servicedef = Object.keys(search.serviceDefenation).map(function (service) {
            if (service)
                return (
                    <div class="card text-center def-item-containes">
                        <div class="card-header">
                            {service}

                        </div>

                        {service === "assistanceandBenefits" ? search.serviceDefenation.assistanceandBenefits.map((def) => (
                            <div class="card-body">
                                <h5 class="card-title">{def.description}</h5>
                                <p class="card-text">{def.definition}</p>
                            </div>
                        )) :
                            service === "home" ? search.serviceDefenation.assistanceandBenefits.map((def) => (
                                <div class="card-body">
                                    <h5 class="card-title">{def.description}</h5>
                                    <p class="card-text">{def.definition}</p>
                                </div>
                            )) :
                                service === "Health" ? search.serviceDefenation.assistanceandBenefits.map((def) => (
                                    <div class="card-body">
                                        <h5 class="card-title">{def.description}</h5>
                                        <p class="card-text">{def.definition}</p>
                                    </div>
                                )) :
                                    service === "Community" ? search.serviceDefenation.assistanceandBenefits.map((def) => (
                                        <div class="card-body">
                                            <h5 class="card-title">{def.description}</h5>
                                            <p class="card-text">{def.definition}</p>
                                        </div>
                                    )) :
                                        service === "Caregiver" ? search.serviceDefenation.assistanceandBenefits.map((def) => (
                                            <div class="card-body">
                                                <h5 class="card-title">{def.description}</h5>
                                                <p class="card-text">{def.definition}</p>
                                            </div>
                                        )) :
                                            service === "transportation" ? search.serviceDefenation.assistanceandBenefits.map((def) => (
                                                <div class="card-body">
                                                    <h5 class="card-title">{def.description}</h5>
                                                    <p class="card-text">{def.definition}</p>
                                                </div>
                                            )) : null

                        }



                    </div>
                )
        })

        // var addresses = props.service.addresses.map(function (address) {
        //     if (address.address_type == 0) {
        //         return (
        //             <li>
        //                 <i><img src="assets/icon/indicator.png" /></i>
        //                 <span>
        //                     {address.address_line_1 + " " + (address.address_line_2 ? address.address_line_2 + " " : "") + " " + address.city + ", " + address.zip}
        //                 </span>
        //             </li>

        //         );
        //     }
        // });

        return (
            <span>
                {Servicedef}
            </span>
        );
    }

    if (shareLoading) {
        return <div className="sweet-loading" style={{
            position: 'absolute',
            left: '50%',
            marginTop: '0%',
            zIndex: 5
        }}>
            <ScaleLoader
                css={override}
                size={100}
                color={"#eebd60"}
                loading={shareLoading}

            />
        </div>
    }

    return (
        <div>
            <div className="sweet-loading" style={{
                position: 'absolute',
                left: '50%',
                marginTop: '0%',
                zIndex: 5
            }}>
                {renderNotification()}
                <ScaleLoader
                    css={override}
                    size={100}
                    color={"#eebd60"}
                    loading={isLoading}

                />
            </div>


            {!help && !glossary && <SearchComponents
                handleSearch={handleSearch}
                handleReset={handleReset}
                handleSelectChange={handleSelectChange}

                categoryOptions={search.category_options}
                serviceCodeOptions={search.service_code_options}
                selectedServices={search.selected_services}
                selectedCategories={search.selected_categories}
                handleServiceProviderTextChange={handleServiceProviderTextChange}
                enteredServiceProviderName={search.entered_service_provider_name}
                countyOptions={search.county_options}
                handleCountyChange={handleCountyChange}
                countyOfLocation={search.county_of_location}
                handleSelectPrice={handleSelectPrice}
                selectedPriceRange={search.selectedPrice}
            />}
            {
                help && <HelpMessageBoard closeModal={openHelpModel} />
            }


            {glossary &&
                <div class="card text-center">
                    <div class="card-body">
                        <div class="card text-center">
                            <div class="card-header">
                                <div class="row" style={{ justifyContent: 'space-around' }}>
                                    <div style={{ marginTop: 10 }}>
                                        <h4>Glossary</h4>

                                    </div>
                                    <button
                                        className="btn btn-link close-glossary "
                                        onClick={handleOpenCloseGlossary}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                            {renderServiceDef()}
                        </div>
                    </div>
                </div>
            }

            <div id="footer-container">
                <div className="row" id="footer-container-inner">
                    <button
                        className="btn btn-link"
                        onClick={openHelpModel}
                    >Help
                    </button>
                    <button
                        className="btn btn-link"
                        onClick={() => handleOpenCloseGlossary()}
                    >Glossary
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Search;
