import React from 'react';
import Select from 'react-select';
// import { Route, Switch } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import queryString from 'query-string'

const options = [
    { value: '$', label: '$' },
    { value: '$$', label: '$$' },
    { value: '$$$', label: '$$$' },
    { value: '$$$$', label: '$$$$' },
];


const Search = (props) => {

    const {
        categoryOptions,
        serviceCodeOptions,
        selectedServices,
        selectedCategories,
        enteredServiceProviderName,
        countyOptions,
        countyOfLocation,
        selectedPriceRange
    } = props;
    const { id } = useParams();
    // const windowUrl = window.location.search;
    // const params = new URLSearchParams(windowUrl);
    // const values = queryString.parse(window.location.search)

    console.log('selectedCategories', selectedCategories)
    const enabled = (selectedCategories.length > 0 || selectedServices.length > 0) || (enteredServiceProviderName && enteredServiceProviderName.length > 2)
    const serviceDisable = Object.keys(selectedCategories).length === 0;
    console.log('serviceDisable', serviceDisable)

    const renderButtons = () => {
        if (enabled) {
            return (
                <div className="btn-toolbar">
                    <button className="btn btn-search" onClick={() => props.handleSearch()}>Search</button>
                    <button type="button" onClick={props.handleReset} className="btn btn-reset">Reset Search</button>
                </div>
            );
        } else {
            return (
                <div className="btn-toolbar">
                    <button onClick={props.handleSearch} disabled className="btn btn-search">Search</button>
                    <button type="button" onClick={props.handleReset} className="btn btn-reset ">Reset Search</button>
                </div>
            );
        }
    }
    return (
        <div className="App">
            <section className="main-form-sec">
                <div className="container">
                    <div className="form-main-title">
                        <h2 className="s-20">Please select, at a minimum, category and service or enter a partial service provider name.</h2>
                    </div>
                    <div className="form-box">
                        <form action="">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="email">Select Category</label>
                                        <Select
                                            options={categoryOptions}
                                            onChange={props.handleSelectChange.bind(this, 'category')}
                                            value={selectedCategories}
                                        // isDisabled={selectedServices && selectedServices.length > 0}

                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Enter Service provider Name</label>
                                        <input type="name" className="form-control" onChange={props.handleServiceProviderTextChange} value={enteredServiceProviderName} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Select Price</label>
                                        <label>
                                            This feature allows you to search by cost, with “$” indicating the least-expensive options and “$$$$” indicating the most expensive. “NA” in search results indicates a service is free, provided on a sliding scale, or pricing information is not available.
                                        </label>
                                        <Select
                                            options={options}
                                            onChange={props.handleSelectPrice}
                                            value={selectedPriceRange}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="email">Select Service </label>
                                        <Select
                                            options={serviceCodeOptions}
                                            onChange={props.handleSelectChange.bind(this, 'service')}
                                            onValueClick={() => console.log('click')}

                                            value={selectedServices}
                                            isMulti
                                            isDisabled={serviceDisable}

                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Counties Served</label>
                                        <Select
                                            options={countyOptions}
                                            onChange={props.handleCountyChange}
                                            value={countyOfLocation}

                                        />
                                    </div>
                                    {/* <div className="form-group">
                                        <label htmlFor="email">Enter Keywords</label>
                                        <input type="name" className="form-control" />
                                    </div> */}
                                </div>
                            </div>

                        </form>

                        <div className="form-btn mt-4">
                            {renderButtons()}
                            {/* <button
                                disabled={
                                    (selectedServices)
                                }
                                //type="submit"
                                className="btn btn-search"
                                onClick={() =>
                                    props.handleSearch()
                                }
                            >
                                Search
                                     </button> */}
                            {/* <button type="submit" onClick={() =>
                                props.handleReset()
                            } className="btn btn-reset">Reset</button>*/}
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}

export default Search;
