
import React, { useState, useEffect, useCallback } from 'react';
import { Route, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ServiceComponents from '../../components/search/Service'
import SearchWizardResultPanelCondensed from './SearchWizardResultPanelCondensed';
import ScaleLoader from "react-spinners/ScaleLoader";
import Doc from '../DocService/DocService';
import PdfContainer from '../DocService/PdfContainer';
import * as searchActions from './Action';
import axios from 'axios';

import util from 'util'
import _ from 'lodash'
import * as type from '../../constant/service/ActionTypes'

const SearchResult = (props) => {
    const dispatch = useDispatch();
    const search = useSelector(state => state.search);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedService, setSelectedService] = useState({});
    const [comment, setComments] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMsj] = useState('');


    console.log('search_results lngth', search.isLoading)

    // useEffect(() => {
    //     if (search.search_results && search.search_results.length > 0) {
    //         setIsLoading(false)
    //     }
    // });

    const renderNotification = () => {
        if (message === 'Thanks for your feedback!') {
            return (
                <div style={{ top: -95, }} className="alert alert-success alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <span>Thanks for your feedback.</span>
                </div>
            )
        } else if (message === 'Feedback not send please check your network!') {
            return (
                <div style={{ top: -95, }} className="alert alert-success alert-dismissible" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <span>Feedback not send please check your network!</span>
                </div>
            )
        }
    }


    const handleFeedback = (service) => {
        console.log('test click', service)
        setSelectedService(service)
    }

    const handleSendFeedback = () => {
        if (!email) {
            alert('Please provide email!')
            return
        }
        if (!comment) {
            alert('Please enter comments!')
            return
        }
        // dispatch(searchActions.sendFeedbackForchangesService(comment, selectedService, email))

        const url = `${type.URL_ROOT}${type.SEND_FEEDBACK_FOR_CHANGES_SERVICE}`

        let bodyParam = {
            service: JSON.stringify(selectedService),
            comment: comment,
            email: email
        }
        // console.log('>>> bodyParam:', bodyParam);

        console.log('url', url)
        axios.post(url, bodyParam)
            .then(response => {
                setEmail('');
                setComments('');
                if (response.status === 200) {
                    setMsj('Thanks for your feedback!')
                } else {
                    setMsj('Feedback not send please check your network!')
                }

            })
            .catch(error => {
                console.log("Crap we are in the catch!");
                console.log("Error : ", util.inspect(error));
            });



    }

    const renderSearchWizardPanel = () => {
        let results = [];

        if (typeof search.search_results !== 'undefined') {
            // let client = this.props.client;
            let myProps = props;

            results = search.search_results.map(function (service) {

                return <>
                    <SearchWizardResultPanelCondensed service={service} key={service.id} handleFeedback={() => handleFeedback(service)} />
                </>;
            }, myProps);

            return results;
        }
        else if (search.server_error) {
            return <div className="alert alert-danger">{search.server_error}</div>;
        } else {
            return <div className="alert alert-danger">No results found!</div>;
        }
    }

    const createPdf = (html) => Doc.createPdf(html);

    return (
        <section class="main-form-sec">
            <div style={{
                position: 'absolute',

                width: '30%',
                left: '40%'
            }}>
                {renderNotification()}

            </div>
            <PdfContainer createPdf={createPdf}>
                <React.Fragment>
                    <section className="flex-column">
                        <div class="container">
                            <div className="sweet-loading" style={{
                                position: 'absolute',
                                left: '50%',
                                // marginTop: '20%',
                            }}>
                                <ScaleLoader
                                    size={100}
                                    color={"#eebd60"}
                                    loading={isLoading}
                                />
                            </div>
                            <div class="row">
                                {renderSearchWizardPanel()}
                            </div>
                        </div>
                    </section>

                    {/* <!-- Modal --> */}
                    <div class="modal" id="myModal">
                        <div class="modal-dialog">

                            <div class="modal-content">
                                <div class="modal-header">
                                    {/* <button type="button" class="close" data-dismiss="modal">&times;</button> */}
                                    <h4 class="modal-title">{selectedService.org_name}</h4>
                                </div>
                                <div class="modal-body">
                                    <p> <strong>Service Description: </strong> {selectedService?.service_code?.description}</p>

                                    <div className="form-group">
                                        <label htmlFor="email">Enter your email</label>
                                        <input type="name" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Comments</label>
                                        <textarea className="form-control" onChange={(e) => setComments(e.target.value)} id="w3review" value={comment} name="w3review" rows="4" cols="50">
                                        </textarea>
                                    </div>


                                    <div className="col-md-4" style={{ marginTop: '-8px' }}>
                                        <button onClick={() => handleSendFeedback()} data-dismiss="modal" className="btn btn-primary btn-sm mt-5">Send Feedback</button>&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </React.Fragment>
            </PdfContainer>

        </section>
    );
}

export default SearchResult;


